/* global i18n */

import onmount from 'onmount';
import TimeMe from 'timeme.js/timeme';
import Rails from '@rails/ujs';
import snackbar from '@components/snackbar';
import {
  showScore, updatePaginationTile, showEditedBy
} from '@modules/grading/scoring';

onmount('[data-js-points-panel]', function () {
  const self = this;
  const {
    editable, assignmentType, startWithZero
  } = this.dataset;
  if (editable === 'false') return;

  const criteria = this.querySelectorAll('[data-js-criterium]');
  const noneOfTheAbove = this.querySelector('[data-js-none-of-the-above]');

  function addConfirmToNoneOfTheAbove() {
    if (noneOfTheAbove) noneOfTheAbove.setAttribute('data-confirm', i18n.t('js.general.are_you_sure'));
  }

  function removeConfirmToNoneOfTheAbove() {
    if (noneOfTheAbove) noneOfTheAbove.removeAttribute('data-confirm');
  }

  function deselectCriteria() {
    criteria.forEach((criterium) => {
      criterium.classList.remove('criterium-selector--selected');
      criterium.classList.add('criterium-selector--toggling');
    });
  }

  function deselectNoneOfTheAbove() {
    if (noneOfTheAbove && noneOfTheAbove.parentElement.classList.contains('criterium-selector--selected')) {
      noneOfTheAbove.parentElement.classList.remove('criterium-selector--selected');
      noneOfTheAbove.parentElement.classList.add('criterium-selector--toggling');
    }
  }

  function submitForm(criterium) {
    if (criterium.classList.contains('criterium-selector--toggling')) return;

    const form = criterium.querySelector('form');
    const input = criterium.querySelector('[data-js-selected-input]');

    if (criterium.classList.contains('criterium-selector--selected')) {
      criterium.classList.remove('criterium-selector--selected');
      input.value = 'false';
    } else {
      criterium.classList.add('criterium-selector--selected');
      input.value = 'true';
    }
    const gradingTimeInput = criterium.querySelector('[data-js-grading-time]');
    gradingTimeInput.value = parseFloat(gradingTimeInput.value || 0) + TimeMe.getTimeOnCurrentPageInSeconds();

    Rails.fire(form, 'submit');
    criterium.classList.add('criterium-selector--toggling');
  }

  function handleCriteriumClick(e) {
    const { target } = e;
    if (!target.hasAttribute('data-js-criterium-toggle')) return;
    if (document.activeElement) document.activeElement.blur();

    const criterium = target.closest('[data-js-criterium]');
    submitForm(criterium);
    deselectNoneOfTheAbove();
  }

  function handleNoneOfTheAboveClick(e) {
    if (noneOfTheAbove.hasAttribute('data-confirm')) return;

    const { target } = e;
    const criterium = target.closest('[data-js-none-above]');

    if (document.activeElement) document.activeElement.blur();
    submitForm(criterium);
    deselectCriteria();
  }

  function handleSuccess(e) {
    const { target } = e;
    if (!target.hasAttribute('data-js-submission-form')) return;

    const data = e.detail[0];
    showScore(data.id, data.score);
    updatePaginationTile(data.id, data.graded, data.score, data.points);
    showEditedBy(self, data.edited_by);

    if (data.score === null) {
      removeConfirmToNoneOfTheAbove();
    } else if (noneOfTheAbove && !noneOfTheAbove.parentElement.classList.contains('criterium-selector--selected')) {
      addConfirmToNoneOfTheAbove();
    }

    if (noneOfTheAbove) noneOfTheAbove.parentElement.classList.remove('criterium-selector--toggling');
    if (target.closest('[data-js-criterium]')) {
      target.closest('[data-js-criterium]').classList.remove('criterium-selector--toggling');
    } else if (target.closest('[data-js-none-above]')) {
      criteria.forEach((criterium) => {
        criterium.classList.remove('criterium-selector--toggling');
      });
    }
  }

  function handleError(e) {
    const { target } = e;
    if (!target.hasAttribute('data-js-submission-form')) return;

    if (navigator.onLine) {
      snackbar(i18n.t('js.gradings.failed_score_save'), true);
    } else {
      snackbar(i18n.t('js.gradings.no_internet'), true);
    }
  }

  function handleSpacebar() {
    if (self.querySelector('.criterium-selector--toggling')) return;

    const affectedCriteria = [];
    let hasSelectedCriterium = false;

    criteria.forEach((criterium) => {
      const points = parseFloat(criterium.dataset.criteriumPoints);

      if ((startWithZero === 'true' && points > 0) || (startWithZero === 'false' && points < 0)) {
        if (criterium.classList.contains('criterium-selector--selected')) hasSelectedCriterium = true;
        affectedCriteria.push(criterium);
      }
    });

    affectedCriteria.forEach((criterium) => {
      if (criterium.classList.contains('criterium-selector--selected') !== hasSelectedCriterium) return;

      criterium.querySelector('[data-js-criterium-toggle]').click();
    });
  }

  function handleKeyUp(e) {
    if (self.closest('.d-none')) return;
    if (e.metaKey || e.ctrlKey || e.shiftKey || e.altKey) return;
    if (['INPUT', 'TEXTAREA', 'MATH-FIELD'].includes(e.target.tagName)) return;
    if (['radio', 'checkbox'].includes(e.target.type)) return;
    if (e.target.closest('.redactor-in')) return;
    if (e.target.closest('.x-spreadsheet')) return;

    if (['0', '§', '`', '~', '/'].includes(e.key)) {
      if (noneOfTheAbove) noneOfTheAbove.click();
    } else if (['1', '2', '3', '4', '5', '6', '7', '8', '9'].includes(e.key)) {
      const criteriumEl = criteria[parseInt(e.key, 10) - 1];
      if (criteriumEl) criteriumEl.querySelector('.criterium-toggle').click();
    } else if (e.key === ' ') {
      handleSpacebar(e);
    }
  }
  this.handleKeyUp = handleKeyUp;

  function handleKeyDown(e) {
    if (e.key === ' ' && e.target === document.body) {
      e.preventDefault();
    }
  }
  this.handleKeyDown = handleKeyDown;

  criteria.forEach((criterium) => {
    criterium.addEventListener('click', handleCriteriumClick);
  });
  this.addEventListener('ajax:success', handleSuccess);
  this.addEventListener('ajax:error', handleError);
  if (noneOfTheAbove) noneOfTheAbove.addEventListener('click', handleNoneOfTheAboveClick);
  if (assignmentType !== 'Assessment' && assignmentType !== 'Appraisal') {
    document.addEventListener('keyup', this.handleKeyUp);
  }
  document.addEventListener('keydown', this.handleKeyDown);

  TimeMe.resetAllRecordedPageTimes();
  TimeMe.startTimer();
}, function () {
  document.removeEventListener('keyup', this.handleKeyUp);
  document.removeEventListener('keydown', this.handleKeyDown);
});
