import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['page', 'hiddenInput', 'submitButton'];

  connect() {
    this.updateSubmitDisabledState();
  }

  pageTargetConnected() {
    this.updateSubmitDisabledState();
  }

  updateSubmitDisabledState() {
    if (this.#allPagesHaveId()) {
      this.#setPageIds();
      this.submitButtonTarget.removeAttribute('disabled');
    } else {
      this.submitButtonTarget.setAttribute('disabled', 'disabled');
    }
  }

  toggleSuggestion(event) {
    const page = event.target.closest('[data-page-id]');
    const { suggestionId } = page.dataset;
    if (page.dataset.pageId) {
      page.dataset.pageId = '';
    } else {
      page.dataset.pageId = suggestionId;
    }
    this.updateSubmitDisabledState();
  }

  #setPageIds() {
    const pageIds = Array.from(this.pageTargets).map((page) => page.dataset.pageId);
    this.hiddenInputTarget.value = pageIds;
  }

  #allPagesHaveId() {
    return Array.from(this.pageTargets).every((page) => page.dataset.pageId);
  }
}
