/* global i18n */
import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['table', 'blankslate'];

  static values = { uploader: String };

  directUploadInitialize(event) {
    const { detail } = event;
    const { file } = detail;
    if (this.hasBlankslateTarget) this.blankslateTarget.remove();
    this.tableTarget.insertAdjacentHTML('afterbegin', `
      <div id="${file.name}" class="box mb-3">
        <div class="box box-filled p-3 d-flex flex-justify-between flex-items-center" data-turbo-permanent>
          <div class="flex-column">
            <div>
              <span class="f4 text-bold">${file.name}</span>
            </div>
            <div class="d-flex flex-items-center">${i18n.t('js.page_collections.uploading')}</div>
          </div>
          <div class="d-flex flex-items-end flex-column">
            <div class="progress-bar d-block my-3" data-progress-bar>
              <span class="progress" style="width: 0%" data-progress></span>
            </div>
          </div>
        </div>
      </div>
    `);
  }

  directUploadProgress(event) {
    const { file, progress } = event.detail;
    const element = this.tableTarget.querySelector(`[id="${file.name}"]`);
    element.querySelector('[data-progress]').style.width = `${progress}%`;
  }

  directUploadError(event) {
    event.preventDefault();
    const { file } = event.detail;
    const element = this.tableTarget.querySelector(`[id="${file.name}"]`);
    element.querySelector('[data-progress-bar]').outerHTML =
      `<div class="flex-column">${i18n.t('js.page_collections.upload_failed')}</div>`;
    element.querySelector('.box').classList.add('box--danger');
  }

  directUploadsEnd() {
    const fileInput = document.querySelector('[name="page_collection[file]"]');
    if (fileInput) fileInput.remove();
    const input = document.getElementById('page_collection_file');
    if (input) input.disabled = false;
  }

  connect() {
    document.addEventListener('direct-upload:initialize', this.directUploadInitialize.bind(this));
    document.addEventListener('direct-upload:progress', this.directUploadProgress.bind(this));
    document.addEventListener('direct-upload:error', this.directUploadError.bind(this));
    document.addEventListener('direct-uploads:end', this.directUploadsEnd.bind(this));
  }

  disconnect() {
    document.removeEventListener('direct-upload:initialize', this.directUploadInitialize.bind(this));
    document.removeEventListener('direct-upload:progress', this.directUploadProgress.bind(this));
    document.removeEventListener('direct-upload:error', this.directUploadError.bind(this));
    document.removeEventListener('direct-uploads:end', this.directUploadsEnd.bind(this));
  }
}
