/* global i18n, Redactor */

import onmount from 'onmount';
import { redactorOptions } from '@redactor/options';
import { initRedactorDialog } from '@redactor/dialog';
import { getRedactor } from '@redactor/helpers';
import { Turbo } from '@hotwired/turbo-rails';

onmount('[data-js-redactor]', function () {
  Turbo.cache.exemptPageFromCache();

  const self = this;
  const redactorType = this.dataset.jsRedactor;
  const { assignmentType, type, column } = this.dataset;
  const variables = this.dataset.jsRedactorVariables === 'true';
  const spellcheck = this.dataset.jsRedactorSpellcheck;
  const autoparse = this.dataset.jsRedactorAutoparse;
  const html = this.dataset.jsRedactorHtml;
  const webcam = this.dataset.jsRedactorWebcam;
  const shortcut = this.dataset.jsRedactorShortcut;
  const counter = this.dataset.jsRedactorCounter;
  const limiter = this.dataset.jsRedactorLimiter;
  const optionList = this.dataset.jsRedactorOptionList;
  const fillInGap = this.dataset.jsRedactorFillInGap;
  const fill = this.dataset.jsRedactorFill;

  function changed() {
    const event = new CustomEvent('redactor:changed', { bubbles: true });
    self.dispatchEvent(event);
  }

  let appLanguage = i18n.locale;
  if (appLanguage === 'nl_vo') appLanguage = 'nl';

  let { plugins, buttons } = redactorOptions;

  if (assignmentType && assignmentType.toLowerCase() === 'exam') {
    plugins = plugins.filter((plugin) => !['audio'].includes(plugin));
    buttons = buttons.filter((button) => !['file'].includes(button));
  }

  if (variables) plugins = plugins.concat('variable');
  if (counter) plugins = plugins.concat('counter');
  if (webcam) plugins = plugins.concat('webcam');
  if (shortcut) plugins = plugins.concat('shortcut', 'shortcutAutocomplete');

  const options = { callbacks: { changed } };

  if (typeof column === 'undefined' || typeof type === 'undefined') {
    plugins = plugins.filter((plugin) => !['audio'].includes(plugin));
    plugins = plugins.filter((plugin) => !['filemanager'].includes(plugin));
    plugins = plugins.filter((plugin) => !['imagemanager'].includes(plugin));
    buttons = buttons.filter((button) => !['image'].includes(button));
    buttons = buttons.filter((button) => !['file'].includes(button));
    options.imageUpload = null;
  }

  if (fill) {
    const subquestion = this.closest('[data-js-subquestion]');
    const optionLists = [...subquestion.querySelectorAll('[data-option-lists-target="optionList"]')].map((list) => (
      { id: list.dataset.id, name: list.dataset.name }));
    plugins = plugins.concat('fill');
    options.optionLists = optionLists;
    options.subquestionId = subquestion.dataset.subquestionId;
  }

  if (optionList) {
    const subquestion = this.closest('[data-js-subquestion]');
    const optionLists = [...subquestion.querySelectorAll('[data-js-option-list]')].map((list) => (
      { id: list.dataset.optionListId, html: list.querySelector('[data-js-list-name]').innerHTML }));
    plugins = plugins.concat('optionList');
    options.optionLists = optionLists;
    options.subquestionId = subquestion.dataset.subquestionId;
  }

  if (limiter && limiter > 0) {
    plugins = plugins.concat('limiter');
    options.limiter = limiter;
  }

  if (autoparse === 'false') {
    buttons = buttons.filter((button) => button !== 'link');
    options.autoparse = false;
  }

  if (html === 'false') buttons = buttons.filter((button) => button !== 'html');

  if (redactorType === 'inline') {
    options.air = true;

    if (!self.hasAttribute('disabled')) {
      plugins = plugins.filter((plugin) => plugin !== 'fullscreen');
      options.callbacks = {
        started: function () { // eslint-disable-line object-shorthand
          if (!this.editor.app.isReadOnly()) {
            const trigger = `<div class="redactor-dialog-trigger cursor-pointer"
                                data-js-redactor-dialog-trigger>
                              <i class="material-icons" data-controller="tooltip"
                                 aria-label="${i18n.t('js.general.formatting')}">text_format</i>
                          </div>`;
            this.editor.container.getElement().append(trigger);
          }
        },
        syncing(cleanedHtml) {
          if (this.editor.isEmpty()) return '';

          return Redactor.options.callbacks.syncing(cleanedHtml);
        },
        enter: function (e) { // eslint-disable-line object-shorthand
          const event = new CustomEvent('redactorEventEnter', { detail: { event: e, redactor: this } });
          document.dispatchEvent(event);

          if (e.metaKey) {
            const form = self.closest('form');
            const submitButton = form.querySelector('button[type="submit"], input[type="submit"]');
            if (submitButton) submitButton.click();
          }

          if (!e.shiftKey) return false;
          return true;
        },
        changed
      };
    }
  }

  if (spellcheck === 'false') {
    options.spellcheck = false;
    options.grammarly = false;
  }

  options.plugins = plugins;
  options.buttons = buttons;
  options.lang = appLanguage;

  if (fillInGap) {
    options.autoparse = false;
    options.plugins = ['specialchars'];
    options.buttons = [];
    options.enterKey = false;
  }

  /* INITIALIZATION */
  getRedactor().then((Redactor) => {
    self.redactor = Redactor(this, options);

    if (self.getAttribute('disabled')) self.redactor.enableReadOnly();
    else if (redactorType === 'inline') initRedactorDialog.apply(self, [self.redactor, options]);
    if (self.hasAttribute('data-js-focus')) self.redactor.editor.endFocus();
  });
}, function () {
  this.redactor.stop();
});
