{
  "de": {
    "js": {
      "accessibility": {
        "button_without_label": "Fehlendes Attribut text oder aria-label",
        "contrast_too_low": "Der Kontrast ist zu gering",
        "element_without_label": "Fehlendes Text-, Titel- oder aria-label-Attribut",
        "image_without_alt": "Fehlendes alt-Attribut",
        "input_missing_label": "Fehlende Beschriftung für oder aria-label-Attribut",
        "label_missing_control": "Etikett fehlt Steuerung",
        "link_without_label_or_role": "Fehlende href oder role=button"
      },
      "analytics": {
        "calculating_statistics": "Berechnen der Statistik...",
        "grading_time": {
          "average_grading_time": "Durchschnittliche Benotungszeit (s)",
          "graded_questions": "Anzahl der benoteten Übungen"
        },
        "overview": {
          "failed": "Nicht bestanden (%{count})",
          "grade": "Noten",
          "number_of_results": "Anzahl der Ergebnisse",
          "participants": " teilnehmer",
          "passed": "Bestanden (%{count})",
          "results": {
            "one": " ergebnis",
            "other": " ergebnisse"
          }
        },
        "rank_correlation": {
          "average_score": "Normalisierte Durchschnittsnote",
          "quintile_group": "Quintil-Gruppe"
        }
      },
      "assignments": {
        "accessibility": {
          "issues": {
            "one": "1 Vorschlag für die Zugänglichkeit",
            "other": "%{count} Vorschläge zur Barrierefreiheit"
          }
        },
        "canon": {
          "sent_onwards": "Erfolgreich weitergeschickt an Prüfungsdienste",
          "successfully_linked": "Erfolgreich verknüpft",
          "successfully_unlinked": "Erfolgreich losgekoppelt",
          "successfully_updated": "Erfolgreich aktualisiert",
          "successfully_uploaded": "Erfolgreich hochgeladen"
        },
        "copy_to_clipboard_success": "Erfolgreich kopiert",
        "criteria": {
          "add": {
            "points": "+ Kriterium hinzufügen",
            "rubrics": "+ Ebene hinzufügen"
          },
          "copy": {
            "points": "+ Kopierkriterien",
            "rubrics": "+ Rubrik kopieren"
          }
        },
        "criteria_functions": {
          "cant_update": "Kann Position für ungültiges Kriterium nicht aktualisieren"
        },
        "form": {
          "allow_multiple_attempts": "Mehrere Versuche zulassen",
          "limit_attempts": "Begrenzen Sie die Anzahl der Versuche"
        },
        "generator": {
          "klasses_count": {
            "one": "1 Klass ausgewählt",
            "other": "%{count} Klassen ausgewählt"
          },
          "missing_content_html": {
            "one": "%{count} Punkt ist fehlender Inhalt. Zur Ansicht <span class='underline'>hier</span> klicken.",
            "other": "%{count} Elemente sind fehlende Inhalte. Klicken Sie <span class='underline'>hier</span>, um den ersten Artikel anzuzeigen."
          }
        },
        "grade_calculation": {
          "grade_calculation_updated": "Notenberechnung erfolgreich geändert"
        },
        "index": {
          "click_on_the_plus": "Klicken Sie auf das Plus, um einen Auftrag hinzuzufügen",
          "create_your_assignment": "Erstellen Sie Ihre Aufgabe"
        },
        "locations_count": {
          "one": "1 Standorte ausgewählt",
          "other": "%{count} Standorten ausgewählt"
        },
        "must_select_class": "Sie müssen eine Klasse auswählen",
        "no_permission": "Sie haben keine Erlaubnis, diese Nachricht zu senden",
        "path": {
          "question_count": {
            "one": "%{count} Übung",
            "other": "%{count} Übungen"
          }
        },
        "saving_failed": "Partituren nicht gespeichert"
      },
      "changes": {
        "leave": "Verlassen Sie",
        "leave_without_saving": "Seite verlassen ohne zu speichern?",
        "stay": "Bleiben Sie",
        "unsaved_changes": "Sie haben nicht gespeicherte Änderungen. Speichern Sie den Inhalt, bevor Sie die Seite verlassen, sonst geht er verloren."
      },
      "courses": {
        "edit": {
          "periods_count": {
            "one": "1 Periode ausgewählt",
            "other": "%{count} Perioden ausgewählt"
          },
          "studies_count": {
            "one": "1 Studie ausgewählt",
            "other": "%{count} Studies ausgewählt"
          }
        },
        "feed": {
          "active": "Aktiv"
        }
      },
      "dashboard": {
        "active_students": {
          "users": "Benutzer"
        },
        "instructor_charts": {
          "instructors": "Kursleitern"
        },
        "login_activity": {
          "logins": "Angemeldete Benutzer",
          "staff_logins": "Mitarbeiter Logins",
          "student_logins": "Schüleranmeldungen"
        },
        "results_forecast": {
          "exam_results": "Schriftliche Aufnahmen",
          "expected_exam_results": "Erwartete schriftliche Aufnahmen",
          "expected_quiz_results": "Erwartete digitale Aufnahmen",
          "quiz_results": "Digitale Aufnahmen"
        }
      },
      "data_table": {
        "all_n_items_selected_html": "Alle <span class=\"text-semi-bold\">%{count}</span> Die Einträge in der Tabelle wurden ausgewählt.",
        "n_items_selected_html": "<span class=\"text-semi-bold\">%{count}</span> Elemente ausgewählt wurden.",
        "select_all_n_items": "Alle %{count} Elemente aus der Tabelle auswählen",
        "undo_selection": "Auswahl rückgängig machen"
      },
      "domains": {
        "index": {
          "objectives": {
            "one": "%{count} Zielsetzung",
            "other": "%{count} Ziele",
            "zero": ""
          }
        },
        "select_domain": "Wählen Sie eine zu kopierende Domäne",
        "show": {
          "new_objective": "Neues Ziel"
        }
      },
      "dropzone": {
        "dictCancelUpload": "Upload abbrechen",
        "dictCancelUploadConfirmation": "Sind Sie sicher, dass Sie den Upload abbrechen wollen?",
        "dictDefaultMessage": "Ziehen Sie Dateien zum Hochladen hierher",
        "dictFallbackMessage": "Ihr Browser unterstützt kein Drag'n'Drop",
        "dictFallbackText": "Verwenden Sie das folgende Formular zum Hochladen von Dateien",
        "dictFileTooBig": "Datei ist zu groß ({filesize}}MiB. Maximale Dateigröße: {{maxFilesize}}MiB.",
        "dictInvalidFileType": "Dieser Dateityp ist nicht erlaubt.",
        "dictMaxFilesExceeded": "Sie können keine weiteren Dateien hochladen.",
        "dictRemoveFile": "Datei löschen",
        "dictResponseError": "Hochladen fehlgeschlagen ({{statusCode}}).",
        "dictUploadCanceled": "Upload abgebrochen."
      },
      "feedback": {
        "message_templates": {
          "problem_html": "<strong>Zusammenfassung:</strong><br> <strong>Schritte zur Reproduktion:</strong><br> <strong>Erwartete Ergebnisse:</strong><br> <strong>Tatsächliche Ergebnisse:</strong><br> <strong>Quell-URL:</strong><br>"
        }
      },
      "general": {
        "are_you_sure": "Sind Sie sicher?",
        "cancel": "Abbrechen",
        "close": "Schließen",
        "confirm": "Bestätigen",
        "connection_lost": "Ihr Gerät hat seine Internetzugangsverbindung verloren.",
        "copy_failed": "Kopie fehlgeschlagen",
        "delete": "Löschen",
        "edit": "Bearbeiten",
        "formatting": "Formatierung",
        "insert": "Einfügen",
        "items": {
          "destroyed": {
            "one": "Element wird dauerhaft gelöscht",
            "other": "%{count} Elemente werden dauerhaft gelöscht"
          },
          "no_permission_to_destroy": {
            "one": "Sie haben nicht die Berechtigung, dieses Element dauerhaft zu löschen",
            "other": "Sie haben nicht die Erlaubnis, diese Elemente dauerhaft zu löschen"
          },
          "no_permission_to_remove": {
            "one": "Sie haben nicht die Erlaubnis, dieses Element zu entfernen",
            "other": "Sie haben nicht die Erlaubnis, diese Elemente zu entfernen"
          },
          "no_permission_to_restore": {
            "one": "Sie haben nicht die Erlaubnis, dieses Objekt wiederherzustellen",
            "other": "Sie haben keine Erlaubnis, diese Objekte wiederherzustellen"
          },
          "removed": {
            "one": "Gegenstand wird entfernt",
            "other": "%{count} Elemente werden entfernt"
          },
          "restored": {
            "one": "Gegenstand wird wiederhergestellt",
            "other": "%{count} Elemente werden wiederhergestellt"
          }
        },
        "levels_count": {
          "one": "1 Schulebene ausgewählt",
          "other": "%{count} Schulebenen ausgewählt"
        },
        "no_content": "Kein Inhalt",
        "no_permission": "Sie haben keine Erlaubnis",
        "password_reset": "E-Mail mit Passwort-Anweisungen erfolgreich verschickt",
        "request_timeout": "Die Anfrage ist fehlgeschlagen, bitte überprüfen Sie Ihre Internetverbindung",
        "save": "Speichern",
        "selected_count": {
          "one": "1 ausgewählt",
          "other": "%{count} ausgewählt"
        },
        "session_timedout": "Ihre Sitzung ist abgelaufen. Sie werden in 5 Sekunden zur Anmeldeseite weitergeleitet",
        "session_timeout": "Ihre Sitzung ist abgelaufen. Bitte melden Sie sich an",
        "sort_failed": "Sortierung fehlgeschlagen",
        "subjects_count": {
          "one": "1 Thema ausgewählt",
          "other": "%{count} Themas ausgewählt"
        },
        "successfully_copied": "Erfolgreich kopiert",
        "successfully_created": "Erfolgreich erstellt",
        "successfully_deleted": "Erfolgreich gelöscht",
        "successfully_removed": "Erfolgreich vernichtet",
        "successfully_resubmitted": "Erfolgreich wiedervorgelegt",
        "successfully_submitted": "Erfolgreich eingereicht",
        "successfully_updated": "Erfolgreich aktualisiert",
        "update": "Aktualisieren",
        "update_failed": "Update fehlgeschlagen",
        "updating": "Aktualisierung...",
        "years_count": {
          "one": "1 Schuljahr ausgewählt",
          "other": "%{count} Schuljahren ausgewählt"
        }
      },
      "gradings": {
        "cant_save": "Kommentar kann nicht gespeichert werden. Einreichung von einem anderen Benutzer gesperrt",
        "cant_update": "Flagge kann nicht aktualisiert werden. Einreichung von einem anderen Benutzer gesperrt",
        "failed_score_save": "Partituren nicht gespeichert",
        "grading_undone": "Benotung rückgängig gemacht",
        "no_internet": "Sie scheinen Ihre Internetverbindung verloren zu haben. Bitte verbinden Sie sich erneut und versuchen Sie es erneut",
        "submission_flagged": "Einreichung markiert",
        "submission_unflagged_html": "Einreichung unmarkiert. <a href='#' class='undo-resolve-flag' data-id='%{id}'&gt;Rückgängig</a>",
        "successfully_undone_flag": "Erfolgreich umgedreht"
      },
      "highlights": {
        "cannot_overlap": "Kann sich nicht mit einem anderen Highlight überschneiden",
        "confirm_destroy": "Sind Sie sicher? Alle Kommentare werden ebenfalls entfernt"
      },
      "hotspot": {
        "done_editing": "Fertig bearbeitet",
        "select_area": "Bereich auswählen"
      },
      "integrations": {
        "query_copied": "Abfrage in die Zwischenablage kopiert"
      },
      "klasses": {
        "class_name_email_student_number_missing": "Klassenname, E-Mail und Studentennummer müssen vorhanden sein",
        "email_student_number_missing": "E-Mail und Studentennummer müssen vorhanden sein",
        "successfully_imported": "Erfolgreicher Import aller Benutzer"
      },
      "layouts": {
        "imported": "Erfolgreich importiert!",
        "invited": "Erfolgreiche Einladung!",
        "no_search_results": "Keine Suchergebnisse",
        "pagy": {
          "confirm": "Sind Sie sicher, dass dies Ihre Auswahl klären wird?"
        }
      },
      "limit_items": {
        "items_remaining": {
          "one": "Sie haben noch einen Artikel übrig",
          "other": "Sie haben noch %{count} Artikel übrig"
        },
        "no_items_remaining": "Sie können nicht mehr als %{count} Artikel auswählen"
      },
      "mathlive": {
        "add_column_after": "Spalte hinzufügen nach",
        "add_column_before": "Spalte vor hinzufügen",
        "add_row_after": "Zeile hinzufügen nach",
        "add_row_before": "Zeile vor hinzufügen",
        "borders": "Grenzen",
        "insert_matrix": "Matrix einfügen",
        "remove_column": "Spalte entfernen",
        "remove_row": "Zeile entfernen"
      },
      "option_lists": {
        "new_gap": "Neue Lücke",
        "restore_gap": "Lücke wiederherstellen"
      },
      "page_collections": {
        "upload_failed": "Upload ist fehlgeschlagen, versuchen Sie es erneut",
        "uploading": "Hochladen"
      },
      "pagy": {
        "selected_count": {
          "one": "%{count} ausgewählt",
          "other": "%{count} ausgewählt",
          "zero": ""
        }
      },
      "questions": {
        "all_students": "Alle Studierenden",
        "groups": "Gruppen",
        "groups_size": {
          "one": "1 Gruppe",
          "other": "%{count} Gruppen"
        },
        "klasses": "Klassen",
        "points": {
          "one": "1 Punkt",
          "other": "%{count} Punkte"
        },
        "questions_size": "Größe der Übungen",
        "select_questions": "Übungen auswählen",
        "select_repo_to_store": "Wählen Sie eine Fragenbank",
        "stored_successfully": "Übung erfolgreich gespeichert",
        "subquestions": {
          "one": "1 Frage",
          "other": "%{count} Fragen"
        }
      },
      "quizzes": {
        "allow_screen_access": "Bitte erlauben Sie den Zugriff auf Ihren Bildschirm",
        "allow_webcam_access": "Bitte erlauben Sie den Zugriff auf Ihre Webcam",
        "already_locked": "Sperrung fehlgeschlagen, Frage ist bereits von einem anderen Benutzer gesperrt",
        "click_to_edit": "Zum Bearbeiten anklicken",
        "invalid_cell_content": "Ungültige Formatierung in Zelle verwendet",
        "locked_by_another": "Frage ist durch einen anderen Benutzer gesperrt",
        "no_webcam_found": "Keine Webcam gefunden",
        "plays_remaining": {
          "one": "Sie können das Medienfragment ein weiteres Mal abspielen",
          "other": "Verbleibende Anzahl von Spielen <span class='px-2 b-han-purple white text-semi-bold rounded-1 ml-2'>%{count}</span>",
          "zero": "Sie haben das Limit der verfügbaren Wiederholungen erreicht"
        },
        "proctorio_session_will_end": "Proctorio-Sitzung wird beendet",
        "quiz_navigation_panel": {
          "unanswered": {
            "one": "Sie haben %{count} unbeantwortete Übung",
            "other": "Sie haben %{count} unbeantwortete Fragen",
            "zero": "Sie haben alle Übungen beantwortet"
          }
        },
        "request_timeout": "Konnte keine Antworten speichern, bitte überprüfen Sie Ihre Internetverbindung",
        "saving_in_progress": "Warten, bis Antworten gespeichert sind",
        "select_shortcut": "Sie müssen einen Kommentar auswählen",
        "session_timeout": "Ihre Sitzung ist abgelaufen. Bitte melden Sie sich erneut an",
        "unsaved_changes": "Sie haben ungespeicherte Nachrichten, sind Sie sicher, dass Sie gehen möchten?"
      },
      "repo_assignments": {
        "cant_remove_question": "Sie haben keine Berechtigung zum Entfernen von Zeile(n)",
        "question_removed": "Fragen aus dem Auftrag entfernt"
      },
      "repos": {
        "assignments": {
          "cant_remove_question": "Sie haben keine Berechtigung zum Entfernen von Zeile(n)",
          "question_removed": "Fragen aus dem Auftrag entfernt"
        },
        "edit": {
          "studies_count": {
            "one": "1 Studie ausgewählt",
            "other": "%{count} ausgewählte Studien"
          }
        },
        "must_select_question": "Sie müssen eine Frage zum Kopieren auswählen",
        "users_removed": "Benutzer vom Repo entfernt"
      },
      "results": {
        "approved": "Ergebnis(se) genehmigt",
        "certainty_analysis": {
          "centration": "Zentrierung des Ergebnisses",
          "centration_over": "Überschätzt",
          "centration_under": "Unterschätzt",
          "correct": "Richtige Antworten",
          "incorrect": "Falsche Antworten"
        },
        "deleted": "Ergebnis(se) gelöscht",
        "disapproved": "Ergebnis(se) missbilligt",
        "domains": {
          "assignment_date": "Datum der Zuweisung",
          "median": "Median",
          "median_percentile_reference_group": "Referenzgruppe Perzentil 50 (Median)",
          "nth_percentile": "%{count}, Perzentil",
          "participant_score": "Teilnehmer-Punktzahl",
          "percentile_reference_group_range": "Perzentil der Referenzgruppe %{range}",
          "score": "Ergebnis"
        },
        "insights_requested": "Einblicke erwünscht. Dies kann eine Weile dauern...",
        "restored": "Ergebnis(se) wiederhergestellt",
        "total_points": "Gesamtpunkte"
      },
      "revenue": {
        "revenue": "Umsatzerlöse"
      },
      "revenue_forecast": {
        "cumulative_revenue_forecast": "Kumulierte Umsatzprognose",
        "monthy_revenue": "Monatlicher Umsatz",
        "revenue_forecast": "Umsatzprognose"
      },
      "scans": {
        "duplicate": "%{count} Duplikat",
        "issues": {
          "one": "1 Strichcode nicht erkannt",
          "other": "%{count} Barcodes nicht erkannt"
        },
        "merge_and_next": "Zusammenführen & Weiter",
        "merge_failed": "Die Fusion ist fehlgeschlagen. Die Anzahl der Seiten entspricht nicht der erforderlichen Seitenzahl.",
        "missing": "%{count} fehlt",
        "no_more_errors": "Ende der Fehlerliste",
        "scans_missing": "Es fehlen Seiten",
        "select_all_boxes": "Sie müssen alle Ankreuzfelder markieren",
        "selected": {
          "one": "1 ausgewählt",
          "other": "%{count} ausgewählt",
          "zero": ""
        },
        "unknown": "unbekannt",
        "upload_failed": "Upload fehlgeschlagen. Bitte versuchen Sie es erneut oder kontaktieren Sie den Support",
        "user_not_found": "Konnte den Benutzer nicht finden"
      },
      "subsets": {
        "question_timer_expired": "Der Timer für diese Übung ist abgelaufen. Zuletzt vorgenommene Änderungen wurden nicht gespeichert."
      },
      "university_insights": {
        "subscriptions": {
          "subscriptions": "Abonnements"
        }
      },
      "uploads": {
        "add_resource": "Noch keine Dateien hinzugefügt",
        "failed": "Upload fehlgeschlagen. Bitte versuchen Sie es erneut",
        "file_too_large": "Die Dateigröße überschreitet das Maximum von %{filesize}",
        "files_selected": {
          "one": "1 Ausgewählte Dateien",
          "other": "%{count} Ausgewählte Dateien"
        },
        "not_found": "Datei nicht gefunden",
        "pending_uploads": "Ausstehende Uploads",
        "unsupported_file_type": ".%{file_extension} ist ein nicht unterstützter Dateityp, bitte zippen Sie diese Datei vor dem Hochladen"
      },
      "users": {
        "add": "Hinzufügen",
        "adding": "Hinzufügen...",
        "edit": {
          "hide_password": "Passwort ausblenden",
          "show_password": "Passwort anzeigen"
        },
        "invite_resent": "Erfolgreiche Wiedereinladung",
        "invite_revoked": "Einladung erfolgreich widerrufen",
        "please_select_two": "Bitte wählen Sie zwei Benutzer",
        "student_number_missing": "Studentennummer muss vorhanden sein",
        "successfully_moved": "Erfolgreich bewegt"
      }
    }
  },
  "en": {
    "js": {
      "accessibility": {
        "button_without_label": "Missing text or aria-label attribute",
        "contrast_too_low": "Contrast is too low",
        "element_without_label": "Missing text, title, or aria-label attribute",
        "image_without_alt": "Missing alt attribute",
        "input_missing_label": "Missing label for or aria-label attribute",
        "label_missing_control": "Label missing control",
        "link_without_label_or_role": "Missing href or role=button"
      },
      "analytics": {
        "calculating_statistics": "Calculating the statistics...",
        "grading_time": {
          "average_grading_time": "Average grading time (s)",
          "graded_questions": "Number of graded exercises"
        },
        "overview": {
          "failed": "Failed (%{count})",
          "grade": "Mark",
          "number_of_results": "Number of results",
          "participants": " participants",
          "passed": "Passed (%{count})",
          "results": {
            "one": " result",
            "other": " results"
          }
        },
        "rank_correlation": {
          "average_score": "Normalised average score",
          "quintile_group": "Quintile group"
        }
      },
      "assignments": {
        "accessibility": {
          "issues": {
            "one": "1 accessibility suggestion",
            "other": "%{count} accessibility suggestions"
          }
        },
        "canon": {
          "sent_onwards": "Successfully sent onwards to Exam Services",
          "successfully_linked": "Successfully linked",
          "successfully_unlinked": "Successfully unlinked",
          "successfully_updated": "Successfully updated",
          "successfully_uploaded": "Successfully uploaded"
        },
        "copy_to_clipboard_success": "Successfully copied",
        "criteria": {
          "add": {
            "points": "+ Add criterion",
            "rubrics": "+ Add level"
          },
          "copy": {
            "points": "+ Copy criteria",
            "rubrics": "+ Copy rubric"
          }
        },
        "criteria_functions": {
          "cant_update": "Can't update position for invalid criterion"
        },
        "form": {
          "allow_multiple_attempts": "Allow multiple attempts",
          "limit_attempts": "Limit the amount of attempts"
        },
        "generator": {
          "klasses_count": {
            "one": "1 class selected",
            "other": "%{count} classes selected"
          },
          "missing_content_html": {
            "one": "%{count} item is missing content. Click <span class='underline'>here</span> to view.",
            "other": "%{count} items are missing content. Click <span class='underline'>here</span> to view first item."
          }
        },
        "grade_calculation": {
          "grade_calculation_updated": "Mark calculation successfully changed"
        },
        "index": {
          "click_on_the_plus": "Click on the plus to add an assignment",
          "create_your_assignment": "Create your assignment"
        },
        "locations_count": {
          "one": "1 location selected",
          "other": "%{count} locations selected"
        },
        "must_select_class": "You must select a class",
        "no_permission": "You don't have permission to send this message",
        "path": {
          "question_count": {
            "one": "%{count} exercise",
            "other": "%{count} exercises"
          }
        },
        "saving_failed": "Failed to save scores"
      },
      "changes": {
        "leave": "Leave",
        "leave_without_saving": "Leave page without saving?",
        "stay": "Stay",
        "unsaved_changes": "You have unsaved changes. Save content before leaving, otherwise it will be lost."
      },
      "courses": {
        "edit": {
          "periods_count": {
            "one": "1 period selected",
            "other": "%{count} periods selected"
          },
          "studies_count": {
            "one": "1 study selected",
            "other": "%{count} studies selected"
          }
        },
        "feed": {
          "active": "Active"
        }
      },
      "dashboard": {
        "active_students": {
          "users": "Users"
        },
        "instructor_charts": {
          "instructors": "Instructors"
        },
        "login_activity": {
          "logins": "Users logged in",
          "staff_logins": "Staff logins",
          "student_logins": "Student logins"
        },
        "results_forecast": {
          "exam_results": "Written takes",
          "expected_exam_results": "Expected written takes",
          "expected_quiz_results": "Expected digital takes",
          "quiz_results": "Digital takes"
        }
      },
      "data_table": {
        "all_n_items_selected_html": "All <span class=\"text-semi-bold\">%{count}</span> items from the table have been selected.",
        "n_items_selected_html": "<span class=\"text-semi-bold\">%{count}</span> items have been selected.",
        "select_all_n_items": "Select all %{count} items from the table",
        "undo_selection": "Undo selection"
      },
      "domains": {
        "index": {
          "objectives": {
            "one": "%{count} objective",
            "other": "%{count} objectives",
            "zero": ""
          }
        },
        "select_domain": "Select a domain to copy",
        "show": {
          "new_objective": "New objective"
        }
      },
      "dropzone": {
        "dictCancelUpload": "Cancel upload",
        "dictCancelUploadConfirmation": "Are you sure you want to cancel this upload?",
        "dictDefaultMessage": "Drop files here to upload",
        "dictFallbackMessage": "Your browser does not support drag'n'drop file uploads.",
        "dictFallbackText": "Please use the fallback form below to upload your files like in the older days.",
        "dictFileTooBig": "File is too big ({{filesize}}MiB). Max filesize: {{maxFilesize}}MiB.",
        "dictInvalidFileType": "You can't upload files of this type.",
        "dictMaxFilesExceeded": "It is not possible to upload any more files.",
        "dictRemoveFile": "Remove file",
        "dictResponseError": "Server responded with {{statusCode}} code.",
        "dictUploadCanceled": "Upload canceled."
      },
      "feedback": {
        "message_templates": {
          "problem_html": "<strong>Summary:</strong><br> <strong>Steps to reproduce:</strong><br> <strong>Expected results:</strong><br> <strong>Actual results:</strong><br> <strong>Source URL:</strong><br>"
        }
      },
      "general": {
        "are_you_sure": "Are you sure?",
        "cancel": "Cancel",
        "close": "Close",
        "confirm": "Confirm",
        "connection_lost": "Your device lost its internet connection.",
        "copy_failed": "Copy failed",
        "delete": "Delete",
        "edit": "Edit",
        "formatting": "formatting",
        "insert": "Insert",
        "items": {
          "destroyed": {
            "one": "Item is permanently deleted",
            "other": "%{count} items are permanently deleted"
          },
          "no_permission_to_destroy": {
            "one": "You don't have permission to permanently delete this item",
            "other": "You don't have permission to permanently delete these items"
          },
          "no_permission_to_remove": {
            "one": "You don't have permission to remove this item",
            "other": "You don't have permission to remove these items"
          },
          "no_permission_to_restore": {
            "one": "You don't have permission to restore this item",
            "other": "You don't have permission to restore these items"
          },
          "removed": {
            "one": "Item is removed",
            "other": "%{count} items are removed"
          },
          "restored": {
            "one": "Item is restored",
            "other": "%{count} items are restored"
          }
        },
        "levels_count": {
          "one": "1 school level selected",
          "other": "%{count} school levels selected"
        },
        "no_content": "No content",
        "no_permission": "You don't have permission",
        "password_reset": "Email successfully sent with password instructions",
        "request_timeout": "The request failed, please check your internet connection",
        "save": "Save",
        "selected_count": {
          "one": "1 selected",
          "other": "%{count} selected"
        },
        "session_timedout": "Your session has expired. You will be redirected to the sign in page in 5 seconds",
        "session_timeout": "Your session is expired. Please sign in",
        "sort_failed": "Sorting failed",
        "subjects_count": {
          "one": "1 subject selected",
          "other": "%{count} subjects selected"
        },
        "successfully_copied": "Successfully copied",
        "successfully_created": "Successfully created",
        "successfully_deleted": "Successfully deleted",
        "successfully_removed": "Successfully removed",
        "successfully_resubmitted": "Successfully resubmitted",
        "successfully_submitted": "Successfully submitted",
        "successfully_updated": "Successfully updated",
        "update": "Update",
        "update_failed": "Update failed",
        "updating": "Updating...",
        "years_count": {
          "one": "1 school year selected",
          "other": "%{count} school years selected"
        }
      },
      "gradings": {
        "cant_save": "Can't save comment. Submission locked by another user",
        "cant_update": "Can't update flag. Submission locked by another user",
        "failed_score_save": "Failed to save scores",
        "grading_undone": "Grading undone",
        "no_internet": "You seem to have lost your internet connection. Please reconnect and try again",
        "submission_flagged": "Submission flagged",
        "submission_unflagged_html": "Submission unflagged. <a href='#' class='undo-resolve-flag' data-id='%{id}'>Undo</a>",
        "successfully_undone_flag": "Successfully reverted"
      },
      "highlights": {
        "cannot_overlap": "Cannot overlap with another highlight",
        "confirm_destroy": "Are you sure? All comments will be removed as well"
      },
      "hotspot": {
        "done_editing": "Done editing",
        "select_area": "Select area"
      },
      "integrations": {
        "query_copied": "Query copied to clipboard"
      },
      "klasses": {
        "class_name_email_student_number_missing": "Class name, email and student number must be present",
        "email_student_number_missing": "Email and student number must be present",
        "successfully_imported": "Successfully imported all users"
      },
      "layouts": {
        "imported": "Successfully imported!",
        "invited": "Successfully invited!",
        "no_search_results": "No search results",
        "pagy": {
          "confirm": "This will clear your selection, are you sure?"
        }
      },
      "limit_items": {
        "items_remaining": {
          "one": "You can select one more item",
          "other": "You can select %{count} more items"
        },
        "no_items_remaining": "You cannot select more than %{count} items"
      },
      "mathlive": {
        "add_column_after": "Add column after",
        "add_column_before": "Add column before",
        "add_row_after": "Add row after",
        "add_row_before": "Add row before",
        "borders": "Borders",
        "insert_matrix": "Insert matrix",
        "remove_column": "Remove column",
        "remove_row": "Remove row"
      },
      "option_lists": {
        "new_gap": "New gap",
        "restore_gap": "Restore gap"
      },
      "page_collections": {
        "upload_failed": "Upload is failed, try again",
        "uploading": "Uploading"
      },
      "pagy": {
        "selected_count": {
          "one": "%{count} selected",
          "other": "%{count} selected",
          "zero": ""
        }
      },
      "questions": {
        "all_students": "All students",
        "groups": "Groups",
        "groups_size": {
          "one": "1 group",
          "other": "%{count} groups"
        },
        "klasses": "Classes",
        "points": {
          "one": "1 point",
          "other": "%{count} points"
        },
        "questions_size": "Questions size",
        "select_questions": "Select questions",
        "select_repo_to_store": "Select a question bank",
        "stored_successfully": "Exercise stored successfully",
        "subquestions": {
          "one": "1 question",
          "other": "%{count} questions"
        }
      },
      "quizzes": {
        "allow_screen_access": "Please allow access to your screen",
        "allow_webcam_access": "Please allow access to your webcam",
        "already_locked": "Locking failed, question is already locked by another user",
        "click_to_edit": "Click to edit",
        "invalid_cell_content": "Invalid content used in cell",
        "locked_by_another": "Question is locked by another user",
        "no_webcam_found": "No webcam found",
        "plays_remaining": {
          "one": "You can play the media fragment one more time",
          "other": "Remaining number of plays <span class='px-2 b-han-purple white text-semi-bold rounded-1 ml-2'>%{count}</span>",
          "zero": "You have reached the limit of number of plays"
        },
        "proctorio_session_will_end": "Proctorio session will end",
        "quiz_navigation_panel": {
          "unanswered": {
            "one": "You have %{count} unanswered question",
            "other": "You have %{count} unanswered questions",
            "zero": "You have answered all questions"
          }
        },
        "request_timeout": "Couldn't save answers, please check you internet connection",
        "saving_in_progress": "Waiting until answers are saved",
        "select_shortcut": "You must select a comment",
        "session_timeout": "Your session is expired. Please sign in again",
        "unsaved_changes": "You have unsaved messages, are you sure you want to leave?"
      },
      "repo_assignments": {
        "cant_remove_question": "You dont have permissions to remove row(s)",
        "question_removed": "Questions removed from assignment"
      },
      "repos": {
        "assignments": {
          "cant_remove_question": "You dont have permissions to remove row(s)",
          "question_removed": "Questions removed from assignment"
        },
        "edit": {
          "studies_count": {
            "one": "1 study selected",
            "other": "%{count} studies selected"
          }
        },
        "must_select_question": "You must select a question to copy",
        "users_removed": "Users removed from repo"
      },
      "results": {
        "approved": "Result(s) approved",
        "certainty_analysis": {
          "centration": "Centration of the result",
          "centration_over": "Overestimated",
          "centration_under": "Underestimated",
          "correct": "Correct answers",
          "incorrect": "Incorrect answers"
        },
        "deleted": "Result(s) deleted",
        "disapproved": "Result(s) disapproved",
        "domains": {
          "assignment_date": "Assignment date",
          "median": "Median",
          "median_percentile_reference_group": "Reference group percentile 50 (median)",
          "nth_percentile": "%{count}th percentile",
          "participant_score": "Participant score",
          "percentile_reference_group_range": "Reference group percentile %{range}",
          "score": "Score"
        },
        "insights_requested": "Insights requested. This may take a while...",
        "restored": "Result(s) restored",
        "total_points": "Total points"
      },
      "revenue": {
        "revenue": "Revenue"
      },
      "revenue_forecast": {
        "cumulative_revenue_forecast": "Cumulative revenue forecast",
        "monthy_revenue": "Monthly revenue",
        "revenue_forecast": "Revenue forecast"
      },
      "scans": {
        "duplicate": "%{count} duplicate",
        "issues": {
          "one": "1 barcode not recognized",
          "other": "%{count} barcodes not recognized"
        },
        "merge_and_next": "Merge & Next",
        "merge_failed": "Merging failed. The number of pages does not match the required amount of pages.",
        "missing": "%{count} missing",
        "no_more_errors": "End of error list",
        "scans_missing": "There are pages missing",
        "select_all_boxes": "You must select all checkboxes",
        "selected": {
          "one": "1 selected",
          "other": "%{count} selected",
          "zero": ""
        },
        "unknown": "unknown",
        "upload_failed": "Upload failed. Please try again or contact support",
        "user_not_found": "Could not find user"
      },
      "subsets": {
        "question_timer_expired": "Timer for this exercise expired. Last made changes not saved."
      },
      "university_insights": {
        "subscriptions": {
          "subscriptions": "Subscriptions"
        }
      },
      "uploads": {
        "add_resource": "No files added yet",
        "failed": "Upload failed. Please try again",
        "file_too_large": "File size exceeds the maximum of %{filesize}",
        "files_selected": {
          "one": "1 file selected",
          "other": "%{count} files selected"
        },
        "not_found": "File not found",
        "pending_uploads": "Pending uploads",
        "unsupported_file_type": ".%{file_extension} is an unsupported file type, please zip this file before uploading"
      },
      "users": {
        "add": "Add",
        "adding": "Adding...",
        "edit": {
          "hide_password": "Hide password",
          "show_password": "Show password"
        },
        "invite_resent": "Invite successfully resent",
        "invite_revoked": "Invite successfully revoked",
        "please_select_two": "Please select two users",
        "student_number_missing": "Student number must be present",
        "successfully_moved": "Successfully moved"
      }
    }
  },
  "fr": {
    "js": {
      "accessibility": {
        "button_without_label": "Texte ou attribut aria-label manquant",
        "contrast_too_low": "Le contraste est trop faible",
        "element_without_label": "Texte manquant, titre ou attribut aria-label",
        "image_without_alt": "Attribut alt manquant",
        "input_missing_label": "Libellé manquant pour ou l'attribut aria-label",
        "label_missing_control": "Contrôle de l'absence d'étiquette",
        "link_without_label_or_role": "Href ou role=button manquant"
      },
      "analytics": {
        "calculating_statistics": "Calculation des statistiques...",
        "grading_time": {
          "average_grading_time": "Temps moyen de notation (s)",
          "graded_questions": "Nombre d'exercices notés"
        },
        "overview": {
          "failed": "Echec (%{count})",
          "grade": "Note",
          "number_of_results": "Nombre de résultats",
          "participants": " participants",
          "passed": "Réussite (%{count})",
          "results": {
            "one": " résultat",
            "other": " résultats"
          }
        },
        "rank_correlation": {
          "average_score": "Note moyenne normalisée",
          "quintile_group": "Groupe Quintile"
        }
      },
      "assignments": {
        "accessibility": {
          "issues": {
            "one": "1 suggestion d'accessibilité",
            "other": "%{count} suggestions d'accessibilité"
          }
        },
        "canon": {
          "sent_onwards": "Envoyé avec succès aux services d'examen",
          "successfully_linked": "Association réussie",
          "successfully_unlinked": "Dissociation réussie",
          "successfully_updated": "Mise à jour réussie",
          "successfully_uploaded": "Mise en ligne réussie"
        },
        "copy_to_clipboard_success": "Copie réussie",
        "criteria": {
          "add": {
            "points": "+ Ajouter un critère",
            "rubrics": "+ Ajouter un niveau"
          },
          "copy": {
            "points": "+ Copier critères",
            "rubrics": "+ Copier la rubrique"
          }
        },
        "criteria_functions": {
          "cant_update": "Impossible de mettre à jour la position pour un critère non valide"
        },
        "form": {
          "allow_multiple_attempts": "Autoriser plusieurs tentatives",
          "limit_attempts": "Limiter le nombre de tentatives"
        },
        "generator": {
          "klasses_count": {
            "one": "1 classe électionnée",
            "other": "%{count} classe électionnée"
          },
          "missing_content_html": {
            "one": "L'élément%{count} manque d'un contenu. Cliquez <span class='underline'>ici</span> pour le consulter.",
            "other": "de%{count} éléments manquants de contenus. Cliquez <span class='underline'>ici</span> pour voir le premier élément."
          }
        },
        "grade_calculation": {
          "grade_calculation_updated": "Le calcul des notes a été modifié avec succès"
        },
        "index": {
          "click_on_the_plus": "Cliquez sur le plus pour ajouter un examen",
          "create_your_assignment": "Créez votre examen"
        },
        "locations_count": {
          "one": "1 localisation sélectionnée",
          "other": "%{count} localisation électionnée"
        },
        "must_select_class": "Vous devez sélectionner une classe",
        "no_permission": "Vous n'avez pas la permission d'envoyer ce message",
        "path": {
          "question_count": {
            "one": "%{count} exercice",
            "other": "%{count} exercices"
          }
        },
        "saving_failed": "Echec de la sauvegarde des scores"
      },
      "changes": {
        "leave": "Quitter",
        "leave_without_saving": "Quitter la page sans sauvegarder ?",
        "stay": "Rester",
        "unsaved_changes": "Vous avez des modifications non enregistrées. Sauvegardez le contenu avant de quitter, sinon il sera perdu."
      },
      "courses": {
        "edit": {
          "periods_count": {
            "one": "1 période sélectionnée",
            "other": "%{count} périodes sélectionnées"
          },
          "studies_count": {
            "one": "1 Étude sélectionné",
            "other": "%{count} Études sélectionnés"
          }
        },
        "feed": {
          "active": "Actif"
        }
      },
      "dashboard": {
        "active_students": {
          "users": "Utilisateurs"
        },
        "instructor_charts": {
          "instructors": "Instructeurs"
        },
        "login_activity": {
          "logins": "Utilisateurs connectés",
          "staff_logins": "Connexions du personnel",
          "student_logins": "Connexions étudiants"
        },
        "results_forecast": {
          "exam_results": "Prises écrites",
          "expected_exam_results": "Prises écrites attendues",
          "expected_quiz_results": "Prises numériques attendues",
          "quiz_results": "Prises numériques"
        }
      },
      "data_table": {
        "all_n_items_selected_html": "Tous les <span class=\"text-semi-bold\">%{count}</span> éléments de ce tableau sont sélectionnés.",
        "n_items_selected_html": "<span class=\"text-semi-bold\">%{count}</span> éléments sont sélectionnés.",
        "select_all_n_items": "Sélectionnez tous les %{count} éléments du tableau",
        "undo_selection": "Annuler la sélection"
      },
      "domains": {
        "index": {
          "objectives": {
            "one": "%{count} objectif",
            "other": "%{count} objectifs",
            "zero": ""
          }
        },
        "select_domain": "Sélectionnez un domaine à copier",
        "show": {
          "new_objective": "Nouvel objectif"
        }
      },
      "dropzone": {
        "dictCancelUpload": "Annuler l'importation.",
        "dictCancelUploadConfirmation": "Êtes-vous sûr de vouloir annuler cette importation ?",
        "dictDefaultMessage": "Déposer des fichiers ici pour les importer.",
        "dictFallbackMessage": "Votre navigateur ne prend pas en charge l'import de fichiers par glisser-déposer.",
        "dictFallbackText": "Veuillez utiliser le formulaire de repli ci-dessous pour importer vos fichiers comme autrefois.",
        "dictFileTooBig": "Le fichier est trop gros ({{filesize}}}MiB). Taille maximale du fichier : {{maxFilesize}}MiB.",
        "dictInvalidFileType": "Vous ne pouvez pas importer des fichiers de ce type.",
        "dictMaxFilesExceeded": "Vous ne pouvez plus importer de fichiers.",
        "dictRemoveFile": "Supprimer le fichier",
        "dictResponseError": "Le serveur a répondu avec le code {{statusCode}}.",
        "dictUploadCanceled": "Importation annulée."
      },
      "feedback": {
        "message_templates": {
          "problem_html": "<strong>Résumé :</strong><br> <strong> Étapes à reproduire :</strong><br> <strong>Résultats attendus :</strong><br> <strong>Résultats réels :</strong><br> <strong>URL source :</strong><br>"
        }
      },
      "general": {
        "are_you_sure": "Etes-vous sûr ?",
        "cancel": "Annuler",
        "close": "Fermer",
        "confirm": "Confirmer",
        "connection_lost": "Votre appareil a perdu sa connexion internet.",
        "copy_failed": "Échec de la copie",
        "delete": "Supprimer",
        "edit": "Modifier",
        "formatting": "Le formatage",
        "insert": "Insérer",
        "items": {
          "destroyed": {
            "one": "L'élément est définitivement supprimé",
            "other": "%{count} les éléments sont définitivement supprimés"
          },
          "no_permission_to_destroy": {
            "one": "Vous n'avez pas l'autorisation de supprimer définitivement cet élément",
            "other": "Vous n'avez pas l'autorisation de supprimer définitivement ces éléments"
          },
          "no_permission_to_remove": {
            "one": "Vous n'avez pas la permission de retirer cet élément",
            "other": "Vous n'avez pas la permission de retirer ces éléments"
          },
          "no_permission_to_restore": {
            "one": "Vous n'avez pas l'autorisation de restaurer cet élément",
            "other": "Vous n'avez pas la permission de restaurer ces éléments"
          },
          "removed": {
            "one": "L'élément est supprimé",
            "other": "%{count} les éléments sont supprimés"
          },
          "restored": {
            "one": "L'élément est restauré",
            "other": "%{count} éléments sont restaurés"
          }
        },
        "levels_count": {
          "one": "1 niveau sélectionnée",
          "other": "%{count} niveaux sélectionnés"
        },
        "no_content": "Pas de contenu",
        "no_permission": "Vous n'avez pas d'autorisation",
        "password_reset": "Courriel envoyé avec succès avec les instructions pour le mot de passe",
        "request_timeout": "La demande a échoué, veuillez vérifier votre connexion internet",
        "save": "Sauvegarder",
        "selected_count": {
          "one": "1 sélectionné",
          "other": "%{count} sélectionnés"
        },
        "session_timedout": "Votre session a expiré. Vous serez redirigé vers la page de connexion dans 5 secondes",
        "session_timeout": "Votre session est terminée. Veuillez vous connecter",
        "sort_failed": "Le tri a échoué",
        "subjects_count": {
          "one": "1 sujet sélectionnée",
          "other": "%{count} sujets sélectionnés"
        },
        "successfully_copied": "Copie réussie",
        "successfully_created": "Créé avec succès",
        "successfully_deleted": "Supprimé avec succès",
        "successfully_removed": "Suppression réussie",
        "successfully_resubmitted": "Soumis à nouveau avec succès",
        "successfully_submitted": "Soumis avec succès",
        "successfully_updated": "Mise à jour réussie",
        "update": "Mise à jour",
        "update_failed": "La mise à jour a échoué",
        "updating": "Mise à jour...",
        "years_count": {
          "one": "1 année scolaire sélectionnée",
          "other": "%{count} années scolaires sélectionnées"
        }
      },
      "gradings": {
        "cant_save": "Impossible d'enregistrer un commentaire. Soumission bloquée par un autre utilisateur",
        "cant_update": "Impossible de mettre à jour le signalement. Soumission bloquée par un autre utilisateur",
        "failed_score_save": "Echec de la sauvegarde des scores",
        "grading_undone": "Notation annulée",
        "no_internet": "Vous semblez avoir perdu votre connexion Internet. Veuillez vous reconnecter et réessayer",
        "submission_flagged": "Soumission signalée",
        "submission_unflagged_html": "Soumission non signalée. <a href='#' class='undo-resolve-flag' data-id='%{id}'&gt;Undo</a>",
        "successfully_undone_flag": "Réversion  réussie"
      },
      "highlights": {
        "cannot_overlap": "Ne peut pas se superposer à un autre highlight",
        "confirm_destroy": "Etes-vous sûr ? Tous les commentaires seront également supprimés"
      },
      "hotspot": {
        "done_editing": "Terminer la modification",
        "select_area": "Sélectionnez un espace"
      },
      "integrations": {
        "query_copied": "Requête copiée dans le presse-papiers"
      },
      "klasses": {
        "class_name_email_student_number_missing": "Le nom de la classe, l'adresse e-mail et le numéro d'étudiant doivent être présents",
        "email_student_number_missing": "L'adresse e-mail et le numéro d'étudiant doivent être présents",
        "successfully_imported": "Importation de tous les utilisateurs réussie"
      },
      "layouts": {
        "imported": "Importé avec succès !",
        "invited": "Invitation réussie !",
        "no_search_results": "Aucun résultat de recherche",
        "pagy": {
          "confirm": "Cela effacera votre sélection, êtes-vous sûr ?"
        }
      },
      "limit_items": {
        "items_remaining": {
          "one": "Vous pouvez sélectionner un autre élément",
          "other": "Vous pouvez sélectionner %{count} éléments"
        },
        "no_items_remaining": "Vous ne pouvez pas sélectionner plus de %{count} éléments"
      },
      "mathlive": {
        "add_column_after": "Ajouter une colonne après",
        "add_column_before": "Ajouter une colonne avant",
        "add_row_after": "Ajouter une ligne après",
        "add_row_before": "Ajouter une ligne avant",
        "borders": "Bordures",
        "insert_matrix": "Insérer matrice",
        "remove_column": "Supprimer colonne",
        "remove_row": "Supprimer ligne"
      },
      "option_lists": {
        "new_gap": "Nouvelle brèche",
        "restore_gap": "Rétablir l'écart"
      },
      "page_collections": {
        "upload_failed": "Le téléchargement a échoué, réessayez",
        "uploading": "Téléchargement"
      },
      "pagy": {
        "selected_count": {
          "one": "%{count} sélectionné",
          "other": "%{count} sélectionné",
          "zero": ""
        }
      },
      "questions": {
        "all_students": "Tous les étudiants",
        "groups": "Groupes",
        "groups_size": {
          "one": "1 groupe",
          "other": "%{count} groupes"
        },
        "klasses": "Classes",
        "points": {
          "one": "1 point",
          "other": "%{count} points"
        },
        "questions_size": "Taille des questions",
        "select_questions": "Sélectionnez les questions",
        "select_repo_to_store": "Sélectionnez une banque de questions",
        "stored_successfully": "Exercice stocké avec succès",
        "subquestions": {
          "one": "1 question",
          "other": "%{count} questions"
        }
      },
      "quizzes": {
        "allow_screen_access": "Veuillez autoriser l'accès à votre écran",
        "allow_webcam_access": "Veuillez autoriser l'accès à votre webcam",
        "already_locked": "Le verrouillage a échoué, la question est déjà verrouillée par un autre utilisateur",
        "click_to_edit": "Cliquez pour modifier",
        "invalid_cell_content": "Contenu non valide utilisé dans la cellule",
        "locked_by_another": "La question est bloqué par un autre utilisateur",
        "no_webcam_found": "Aucune webcam trouvée",
        "plays_remaining": {
          "one": "Vous pouvez reproduire l'élément multimédial une dernière fois",
          "other": "Nombre de reproductions restantes <span class='px-2 b-han-purple white text-semi-bold rounded-1 ml-2'>%{count}</span>",
          "zero": "Vous avez atteint la limite de reproductions disponibles"
        },
        "proctorio_session_will_end": "Fin de la session Proctorio",
        "quiz_navigation_panel": {
          "unanswered": {
            "one": "Vous avez %{count} exercice sans réponse",
            "other": "Vous avez %{count} exercices sans réponse",
            "zero": "Vous avez répondu à tous les exercices"
          }
        },
        "request_timeout": "Impossible de sauvegarder les réponses, veuillez vérifier votre connexion Internet",
        "saving_in_progress": "Attendre que les réponses soient sauvegardées",
        "select_shortcut": "Vous devez sélectionner un commentaire",
        "session_timeout": "Votre session est terminée. Veuillez vous reconnecter",
        "unsaved_changes": "Vous avez des messages non sauvegardés, vous êtes sûr de vouloir quitter ?"
      },
      "repo_assignments": {
        "cant_remove_question": "Vous n'avez pas l'autorisation de supprimer une ou plusieurs lignes",
        "question_removed": "Questions retirées de l'examen"
      },
      "repos": {
        "assignments": {
          "cant_remove_question": "Vous n'avez pas l'autorisation de supprimer une ou plusieurs lignes",
          "question_removed": "Questions retirées de l'examen"
        },
        "edit": {
          "studies_count": {
            "one": "1 étude sélectionné",
            "other": "%{count} études sélectionnés"
          }
        },
        "must_select_question": "Vous devez sélectionner une question à copier",
        "users_removed": "Utilisateurs retirés de la mise en banque de questions"
      },
      "results": {
        "approved": "Résultat(s) approuvé(s)",
        "certainty_analysis": {
          "centration": "Centrage du résultat",
          "centration_over": "Surestimé",
          "centration_under": "Sous-estimé",
          "correct": "Réponses correctes",
          "incorrect": "Réponses incorrectes"
        },
        "deleted": "Résultat(s) supprimé(s)",
        "disapproved": "Résultat(s) désapprouvé(s)",
        "domains": {
          "assignment_date": "Date de l'examen",
          "median": "Médiane",
          "median_percentile_reference_group": "Groupe de référence percentile 50 (médiane)",
          "nth_percentile": "%{count}th percentile",
          "participant_score": "Score du participant",
          "percentile_reference_group_range": "Percentile du groupe de référence %{range}",
          "score": "Score"
        },
        "insights_requested": "Analyse demandée. Cela peut prendre un certain temps...",
        "restored": "Résultat(s) restauré(s)",
        "total_points": "Total des points"
      },
      "revenue": {
        "revenue": "Revenu"
      },
      "revenue_forecast": {
        "cumulative_revenue_forecast": "Prévision cumulative de revenus",
        "monthy_revenue": "Revenu mensuel",
        "revenue_forecast": "Prévisions de revenus"
      },
      "scans": {
        "duplicate": "%{count} doublon",
        "issues": {
          "one": "1 code à barres non reconnu",
          "other": "%{count} codes à barres non reconnus"
        },
        "merge_and_next": "Fusionner & Suivant",
        "merge_failed": "La fusion a échoué. Le nombre de pages ne correspond pas au nombre de pages requis.",
        "missing": "%{count} manquants",
        "no_more_errors": "Fin de la liste d'erreurs",
        "scans_missing": "Il manque des pages",
        "select_all_boxes": "Vous devez cocher toutes les cases",
        "selected": {
          "one": "1 sélectionné",
          "other": "%{count} sélectionnés",
          "zero": ""
        },
        "unknown": "inconnu",
        "upload_failed": "L'importation a échouée. Veuillez réessayer ou contacter le support",
        "user_not_found": "Impossible de trouver l'utilisateur"
      },
      "subsets": {
        "question_timer_expired": "Le temps pour cet exercice est terminé. Les dernières modifications apportées n'ont pas été enregistrées."
      },
      "university_insights": {
        "subscriptions": {
          "subscriptions": "Abonnements"
        }
      },
      "uploads": {
        "add_resource": "Aucun fichier n'a encore été ajouté",
        "failed": "L'importation a échouée. Veuillez réessayer",
        "file_too_large": "La taille du fichier dépasse le maximum de %{filesize}",
        "files_selected": {
          "one": "1 fichier sélectionnés",
          "other": "%{count} fichiers sélectionnés"
        },
        "not_found": "Fichier non trouvé",
        "pending_uploads": "Importations en cours",
        "unsupported_file_type": ".%{file_extension} est un type de fichier non pris en charge, veuillez zipper ce fichier avant de l'importer"
      },
      "users": {
        "add": "Ajouter",
        "adding": "Ajout...",
        "edit": {
          "hide_password": "Cacher le mot de passe",
          "show_password": "Afficher le mot de passe"
        },
        "invite_resent": "Invitation renvoyée avec succès",
        "invite_revoked": "Invitation révoquée avec succès",
        "please_select_two": "Veuillez sélectionner deux utilisateurs",
        "student_number_missing": "Le numéro étudiant doit être présent",
        "successfully_moved": "Déplacement réussi"
      }
    }
  },
  "lv": {
    "js": {
      "accessibility": {
        "button_without_label": "Trūkst teksta vai aria-label atribūta",
        "contrast_too_low": "Kontrasts ir pārāk zems",
        "element_without_label": "Trūkst teksta, virsraksta vai aria-label atribūta",
        "image_without_alt": "Trūkst alt atribūta",
        "input_missing_label": "Trūkst marķējuma vai aria-label atribūtam",
        "label_missing_control": "Trūkstošā etiķetes kontrole",
        "link_without_label_or_role": "Trūkst href vai role=button"
      },
      "analytics": {
        "calculating_statistics": "Statistikas aprēķināšana...",
        "grading_time": {
          "average_grading_time": "Vidējais šķirošanas laiks (s)",
          "graded_questions": "Novērtēto vingrinājumu skaits"
        },
        "overview": {
          "failed": "Neveiksmīgs (%{count})",
          "grade": "Mark",
          "number_of_results": "Rezultātu skaits",
          "participants": " dalībnieki",
          "passed": "Nodots (%{count})",
          "results": {
            "one": " rezultāts",
            "other": " rezultāti"
          }
        },
        "rank_correlation": {
          "average_score": "Normalizētais vidējais rezultāts",
          "quintile_group": "Kvintiļu grupa"
        }
      },
      "assignments": {
        "accessibility": {
          "issues": {
            "one": "1 ieteikums par pieejamību",
            "other": "%{count} ieteikumi par pieejamību"
          }
        },
        "canon": {
          "sent_onwards": "Veiksmīgi nosūtīts tālāk eksaminācijas dienestiem",
          "successfully_linked": "Veiksmīgi savienots",
          "successfully_unlinked": "Veiksmīgi atvienots",
          "successfully_updated": "Veiksmīgi atjaunināts",
          "successfully_uploaded": "Veiksmīgi augšupielādēts"
        },
        "copy_to_clipboard_success": "Veiksmīgi nokopēts",
        "criteria": {
          "add": {
            "points": "+ Pievienot kritēriju",
            "rubrics": "+ Pievienot līmeni"
          },
          "copy": {
            "points": "+ Kopēšanas kritēriji",
            "rubrics": "+ Kopēt rubriku"
          }
        },
        "criteria_functions": {
          "cant_update": "Nevar atjaunināt pozīciju nederīga kritērija dēļ"
        },
        "form": {
          "allow_multiple_attempts": "Atļaut vairākus mēģinājumus",
          "limit_attempts": "Ierobežojiet mēģinājumu skaitu"
        },
        "generator": {
          "klasses_count": {
            "one": "izvēlēta 1 klase",
            "other": "%{count} atlasītās klases"
          },
          "missing_content_html": {
            "one": "%{count} vienumam trūkst satura. Noklikšķiniet <span class='underline'>šeit,</span> lai apskatītu.",
            "other": "%{count} trūkst satura. Noklikšķiniet <span class='underline'>šeit,</span> lai apskatītu pirmo vienumu."
          }
        },
        "grade_calculation": {
          "grade_calculation_updated": "Veiksmīgi mainīts atzīmes aprēķins"
        },
        "index": {
          "click_on_the_plus": "Noklikšķiniet uz plusa, lai pievienotu uzdevumu",
          "create_your_assignment": "Izveidojiet savu uzdevumu"
        },
        "locations_count": {
          "one": "izvēlēta 1 vieta",
          "other": "%{count} atlasītās vietas"
        },
        "must_select_class": "Jāizvēlas klase",
        "no_permission": "Jums nav atļaujas nosūtīt šo ziņojumu",
        "path": {
          "question_count": {
            "one": "%{count} vingrinājums",
            "other": "%{count} vingrinājumi"
          }
        },
        "saving_failed": "Neizdevās saglabāt rezultātus"
      },
      "changes": {
        "leave": "Atstāj",
        "leave_without_saving": "Pamest lapu bez saglabāšanas?",
        "stay": "Palieciet",
        "unsaved_changes": "Jums ir nesaglabātas izmaiņas. Pirms aiziešanas saglabājiet saturu, citādi tas tiks zaudēts."
      },
      "courses": {
        "edit": {
          "periods_count": {
            "one": "izvēlēts 1 periods",
            "other": "%{count} izvēlētie periodi"
          },
          "studies_count": {
            "one": "izvēlēts 1 pētījums",
            "other": "%{count} atlasītie pētījumi"
          }
        },
        "feed": {
          "active": "Aktīvais"
        }
      },
      "dashboard": {
        "active_students": {
          "users": "Lietotāji"
        },
        "instructor_charts": {
          "instructors": "Instruktori"
        },
        "login_activity": {
          "logins": "Lietotāji ir pierakstījušies",
          "staff_logins": "Darbinieku pieteikšanās",
          "student_logins": "Studentu pieteikšanās"
        },
        "results_forecast": {
          "exam_results": "Uzrakstīts aizņem",
          "expected_exam_results": "Paredzamais rakstiskais aizņem",
          "expected_quiz_results": "Paredzamie digitālie ieguvumi",
          "quiz_results": "Digitālā uzņemšana"
        }
      },
      "data_table": {
        "all_n_items_selected_html": "Visi <span class=\"text-semi-bold\">%{count}</span> ir atlasīti tabulas elementi.",
        "n_items_selected_html": "<span class=\"text-semi-bold\">%{count}</span> ir atlasīti vienumi.",
        "select_all_n_items": "Atlasiet visus %{count} vienumus no tabulas",
        "undo_selection": "Atlases atcelšana"
      },
      "domains": {
        "index": {
          "objectives": {
            "one": "%{count} mērķis",
            "other": "%{count} mērķi",
            "zero": ""
          }
        },
        "select_domain": "Atlasiet domēnu, kuru kopēt",
        "show": {
          "new_objective": "Jauns mērķis"
        }
      },
      "dropzone": {
        "dictCancelUpload": "Atcelt augšupielādi",
        "dictCancelUploadConfirmation": "Vai esat pārliecināts, ka vēlaties atcelt šo augšupielādi?",
        "dictDefaultMessage": "Lejupielādējiet failus šeit",
        "dictFallbackMessage": "Jūsu pārlūkprogramma neatbalsta failu augšupielādi ar vilkšanu un nomešanu.",
        "dictFallbackText": "Lai augšupielādētu failus kā sendienās, lūdzu, izmantojiet zemāk redzamo rezerves veidlapu.",
        "dictFileTooBig": "Faili ir pārāk lieli ({{filesize}}MiB). Maksimālais faila izmērs: {{{maxFilesize}}MiB.",
        "dictInvalidFileType": "Šāda veida failus augšupielādēt nevar.",
        "dictMaxFilesExceeded": "Vairāk failu augšupielādēt nav iespējams.",
        "dictRemoveFile": "Failu noņemšana",
        "dictResponseError": "Serveris atbildēja ar {{statusCode}} kodu.",
        "dictUploadCanceled": "Lejupielāde atcelta."
      },
      "feedback": {
        "message_templates": {
          "problem_html": "<strong>Kopsavilkums:</strong><br> <strong>Reproducēšanas soļi:</strong><br> <strong>Paredzamie rezultāti:</strong><br> <strong>Faktiskie rezultāti:</strong><br> <strong>Avota URL:</strong><br>"
        }
      },
      "general": {
        "are_you_sure": "Vai esat pārliecināts?",
        "cancel": "Atcelt",
        "close": "Aizvērt",
        "confirm": "Apstipriniet",
        "connection_lost": "Ierīce zaudēja interneta savienojumu.",
        "copy_failed": "Kopēt neizdevās",
        "delete": "Dzēst",
        "edit": "Rediģēt",
        "formatting": "Formēšana",
        "insert": "Ievietot",
        "items": {
          "destroyed": {
            "one": "Prece ir neatgriezeniski dzēsta",
            "other": "%{count} priekšmeti tiek dzēsti neatgriezeniski"
          },
          "no_permission_to_destroy": {
            "one": "Jums nav atļaujas neatgriezeniski dzēst šo vienumu",
            "other": "Jums nav atļaujas neatgriezeniski dzēst šos vienumus"
          },
          "no_permission_to_remove": {
            "one": "Jums nav atļaujas noņemt šo objektu",
            "other": "Jums nav atļaujas noņemt šos vienumus"
          },
          "no_permission_to_restore": {
            "one": "Jums nav atļaujas atjaunot šo objektu",
            "other": "Jums nav atļaujas atjaunot šos vienumus"
          },
          "removed": {
            "one": "Prece ir noņemta",
            "other": "%{count} vienumi ir noņemti"
          },
          "restored": {
            "one": "Priekšmets ir atjaunots",
            "other": "%{count} vienumi ir atjaunoti"
          }
        },
        "levels_count": {
          "one": "izvēlēts 1 skolas līmenis",
          "other": "%{count} izvēlētie skolu līmeņi"
        },
        "no_content": "Nav satura",
        "no_permission": "Jums nav atļaujas",
        "password_reset": "Veiksmīgi nosūtīts e-pasta vēstule ar paroles norādījumiem",
        "request_timeout": "Pieprasījums neizdevās, lūdzu, pārbaudiet interneta savienojumu",
        "save": "Saglabāt",
        "selected_count": {
          "one": "1 izvēlēts",
          "other": "%{count} atlasīts"
        },
        "session_timedout": "Jūsu sesija ir beigusies. Pēc 5 sekundēm tiksiet novirzīts uz pierakstīšanās lapu",
        "session_timeout": "Jūsu sesija ir beigusies. Lūdzu, pierakstieties",
        "sort_failed": "Šķirošana neizdevās",
        "subjects_count": {
          "one": "izvēlēts 1 priekšmets",
          "other": "%{count} atlasītie subjekti"
        },
        "successfully_copied": "Veiksmīgi nokopēts",
        "successfully_created": "Veiksmīgi izveidots",
        "successfully_deleted": "Veiksmīgi dzēsts",
        "successfully_removed": "Veiksmīgi noņemts",
        "successfully_resubmitted": "Veiksmīgi iesniegts atkārtoti",
        "successfully_submitted": "Veiksmīgi iesniegts",
        "successfully_updated": "Veiksmīgi atjaunināts",
        "update": "Atjaunināt",
        "update_failed": "Atjaunināšana neizdevās",
        "updating": "Atjaunināšana...",
        "years_count": {
          "one": "izvēlēts 1 mācību gads",
          "other": "%{count} izvēlētie mācību gadi"
        }
      },
      "gradings": {
        "cant_save": "Nevar saglabāt komentāru. Iesniegumu bloķējis cits lietotājs",
        "cant_update": "Nevar atjaunināt karogu. Iesniegumu bloķējis cits lietotājs",
        "failed_score_save": "Neizdevās saglabāt rezultātus",
        "grading_undone": "Klasifikācija nav veikta",
        "no_internet": "Šķiet, ka esat zaudējis interneta savienojumu. Lūdzu, atjaunojiet savienojumu un mēģiniet vēlreiz",
        "submission_flagged": "Iesniegšana ar karodziņu",
        "submission_unflagged_html": "Iesniegšana bez karodziņa. <a href='#' class='undo-resolve-flag' data-id='%{id}'>Atcelt</a>",
        "successfully_undone_flag": "Veiksmīgi atcelts"
      },
      "highlights": {
        "cannot_overlap": "Nevar pārklāties ar citu izceltni",
        "confirm_destroy": "Vai esat pārliecināts? Visi komentāri tiks dzēsti"
      },
      "hotspot": {
        "done_editing": "Pabeigta rediģēšana",
        "select_area": "Izvēlieties apgabalu"
      },
      "integrations": {
        "query_copied": "Vaicājums kopēts uz starpliktuvi"
      },
      "klasses": {
        "class_name_email_student_number_missing": "Jānorāda klases nosaukums, e-pasta adrese un skolēna numurs",
        "email_student_number_missing": "Jābūt norādītam e-pasta adresei un skolēna numuram",
        "successfully_imported": "Veiksmīgi importēti visi lietotāji"
      },
      "layouts": {
        "imported": "Veiksmīgi importēts!",
        "invited": "Veiksmīgi uzaicināts!",
        "no_search_results": "Nav meklēšanas rezultātu",
        "pagy": {
          "confirm": "Tas dzēsīs jūsu atlasi, vai esat pārliecināts?"
        }
      },
      "limit_items": {
        "items_remaining": {
          "one": "Varat izvēlēties vēl vienu elementu",
          "other": "Varat atlasīt %{count} vairāk vienumu"
        },
        "no_items_remaining": "Jūs nevarat izvēlēties vairāk par %{count} vienībām"
      },
      "mathlive": {
        "add_column_after": "Pievienot kolonnu pēc",
        "add_column_before": "Pievienot kolonnu pirms",
        "add_row_after": "Pievienot rindu pēc",
        "add_row_before": "Pievienot rindu pirms",
        "borders": "Robežas",
        "insert_matrix": "Ievietot matricu",
        "remove_column": "Noņemt kolonnu",
        "remove_row": "Noņemt rindu"
      },
      "option_lists": {
        "new_gap": "Jauna plaisa",
        "restore_gap": "Atjaunot plaisu"
      },
      "page_collections": {
        "upload_failed": "Augšupielādēt neizdevās, mēģiniet vēlreiz",
        "uploading": "Augšupielāde"
      },
      "pagy": {
        "selected_count": {
          "one": "%{count} atlasīts",
          "other": "%{count} atlasīts",
          "zero": ""
        }
      },
      "questions": {
        "all_students": "Visi studenti",
        "groups": "Grupas",
        "groups_size": {
          "one": "1 grupa",
          "other": "%{count} grupas"
        },
        "klasses": "Klases",
        "points": {
          "one": "1 punkts",
          "other": "%{count} punkti"
        },
        "questions_size": "Jautājumu lielums",
        "select_questions": "Izvēlēties jautājumus",
        "select_repo_to_store": "Izvēlieties jautājumu banku",
        "stored_successfully": "Veiksmīgi saglabāts vingrinājums",
        "subquestions": {
          "one": "1 jautājums",
          "other": "%{count} jautājumi"
        }
      },
      "quizzes": {
        "allow_screen_access": "Lūdzu, atļaujiet piekļuvi ekrānam",
        "allow_webcam_access": "Lūdzu, atļaujiet piekļuvi jūsu tīmekļa kamerai",
        "already_locked": "Bloķēšana neizdevās, jautājumu jau ir bloķējis cits lietotājs",
        "click_to_edit": "Noklikšķiniet, lai rediģētu",
        "invalid_cell_content": "Šūnā izmantots nepareizs saturs",
        "locked_by_another": "Jautājumu ir bloķējis cits lietotājs",
        "no_webcam_found": "Nav atrasta neviena tīmekļa kamera",
        "plays_remaining": {
          "one": "Varat atskaņot multivides fragmentu vēl vienu reizi",
          "other": "Atlikušais spēļu skaits <span class='px-2 b-han-purple white text-semi-bold rounded-1 ml-2'>%{count}</span>",
          "zero": "Jūs esat sasniedzis atskaņojumu skaita limitu"
        },
        "proctorio_session_will_end": "Proctorio sesija beigsies",
        "quiz_navigation_panel": {
          "unanswered": {
            "one": "Jums ir %{count} neatbildēts jautājums",
            "other": "Jums ir %{count} neatbildēti jautājumi",
            "zero": "Jūs esat atbildējis uz visiem jautājumiem"
          }
        },
        "request_timeout": "Nevarēja saglabāt atbildes, lūdzu, pārbaudiet interneta savienojumu",
        "saving_in_progress": "Gaidīšana, līdz atbildes tiek saglabātas",
        "select_shortcut": "Jums ir jāizvēlas komentārs",
        "session_timeout": "Jūsu sesija ir beigusies. Lūdzu, pierakstieties vēlreiz",
        "unsaved_changes": "Jums ir nesaglabāti ziņojumi, vai esat pārliecināts, ka vēlaties atstāt?"
      },
      "repo_assignments": {
        "cant_remove_question": "Jums nav tiesību dzēst rindu(-as)",
        "question_removed": "No uzdevuma izņemti jautājumi"
      },
      "repos": {
        "assignments": {
          "cant_remove_question": "Jums nav tiesību dzēst rindu(-as)",
          "question_removed": "No uzdevuma izņemti jautājumi"
        },
        "edit": {
          "studies_count": {
            "one": "izvēlēts 1 pētījums",
            "other": "%{count} atlasītie pētījumi"
          }
        },
        "must_select_question": "Jāizvēlas jautājums, kuru kopēt",
        "users_removed": "Lietotāji izņemti no repozitorija"
      },
      "results": {
        "approved": "Apstiprināts(-i) rezultāts(-i)",
        "certainty_analysis": {
          "centration": "Rezultāta koncentrācija",
          "centration_over": "Pārvērtēta",
          "centration_under": "Nepietiekami novērtēts",
          "correct": "Pareizās atbildes",
          "incorrect": "Nepareizas atbildes"
        },
        "deleted": "Izdzēsts(-i) rezultāts(-i)",
        "disapproved": "Rezultāts(-i) neapstiprināts(-i)",
        "domains": {
          "assignment_date": "Piešķiršanas datums",
          "median": "Mediāna",
          "median_percentile_reference_group": "Atsauces grupas procentile 50 (mediāna)",
          "nth_percentile": "%{count}trešā procentile",
          "participant_score": "Dalībnieka rezultāts",
          "percentile_reference_group_range": "Atsauces grupas procentile %{range}",
          "score": "Rezultāts"
        },
        "insights_requested": "Pieprasītās atziņas. Tas var aizņemt kādu laiku...",
        "restored": "Rezultāts(-i) atjaunots(-i)",
        "total_points": "Kopējais punktu skaits"
      },
      "revenue": {
        "revenue": "Ieņēmumi"
      },
      "revenue_forecast": {
        "cumulative_revenue_forecast": "Kumulatīvo ieņēmumu prognoze",
        "monthy_revenue": "Mēneša ieņēmumi",
        "revenue_forecast": "Ieņēmumu prognoze"
      },
      "scans": {
        "duplicate": "%{count} dublēt",
        "issues": {
          "one": "1 svītrkods nav atpazīts",
          "other": "%{count} netiek atpazīti svītrkodi"
        },
        "merge_and_next": "Apvienošana un nākamais",
        "merge_failed": "Apvienošana neizdevās. Lappušu skaits neatbilst vajadzīgajam lapu skaitam.",
        "missing": "%{count} trūkst",
        "no_more_errors": "Kļūdu saraksta beigas",
        "scans_missing": "Trūkst lappušu",
        "select_all_boxes": "Jums ir jāatzīmē visi izvēles lodziņi",
        "selected": {
          "one": "1 izvēlēts",
          "other": "%{count} atlasīts",
          "zero": ""
        },
        "unknown": "nezināms",
        "upload_failed": "Neizdevās augšupielādēt. Lūdzu, mēģiniet vēlreiz vai sazinieties ar atbalsta dienestu",
        "user_not_found": "Nevar atrast lietotāju"
      },
      "subsets": {
        "question_timer_expired": "Šī vingrinājuma taimeris ir beidzies. Pēdējās veiktās izmaiņas nav saglabātas."
      },
      "university_insights": {
        "subscriptions": {
          "subscriptions": "Abonēšana"
        }
      },
      "uploads": {
        "add_resource": "Vēl nav pievienoti faili",
        "failed": "Neizdevās augšupielādēt. Lūdzu, mēģiniet vēlreiz",
        "file_too_large": "Faila izmērs pārsniedz maksimālo %{filesize}",
        "files_selected": {
          "one": "izvēlēts 1 fails",
          "other": "%{count} atlasītie faili"
        },
        "not_found": "Faili nav atrasti",
        "pending_uploads": "Neizsniegtās augšupielādes",
        "unsupported_file_type": ".%{file_extension} ir neatbalstīts faila tips, lūdzu, pirms augšupielādēt šo failu aizdrukāiet zip"
      },
      "users": {
        "add": "Pievienot",
        "adding": "Pievienojot...",
        "edit": {
          "hide_password": "Paslēpt paroli",
          "show_password": "Rādīt paroli"
        },
        "invite_resent": "Uzaicinājums veiksmīgi nosūtīts atkārtoti",
        "invite_revoked": "Uzaicinājums veiksmīgi atsaukts",
        "please_select_two": "Lūdzu, izvēlieties divus lietotājus",
        "student_number_missing": "Jābūt skolēna numuram",
        "successfully_moved": "Veiksmīgi pārvietots"
      }
    }
  },
  "nl": {
    "js": {
      "accessibility": {
        "button_without_label": "text of aria-label attribuut ontbreekt",
        "contrast_too_low": "Contrast is te laag",
        "element_without_label": "Text, title, of aria-label attribuut ontbreekt",
        "image_without_alt": "Alt attribuut ontbreekt",
        "input_missing_label": "label for of aria-label attribuut ontbreekt",
        "label_missing_control": "Label mist control",
        "link_without_label_or_role": "Href of role=button ontbreekt"
      },
      "analytics": {
        "calculating_statistics": "Statistieken worden berekend...",
        "grading_time": {
          "average_grading_time": "Gemiddelde nakijktijd (s)",
          "graded_questions": "Aantal nagekeken opgaven"
        },
        "overview": {
          "failed": "Niet behaald (%{count})",
          "grade": "Cijfer",
          "number_of_results": "Aantal resultaten",
          "participants": " deelnemers",
          "passed": "Behaald (%{count})",
          "results": {
            "one": " resultaat",
            "other": " resultaten"
          }
        },
        "rank_correlation": {
          "average_score": "Genormaliseerde gemiddelde score",
          "quintile_group": "Kwintiel"
        }
      },
      "assignments": {
        "accessibility": {
          "issues": {
            "one": "1 toegankelijkheid suggestie",
            "other": "%{count} toegankelijkheid suggesties"
          }
        },
        "canon": {
          "sent_onwards": "Succesvol doorgestuurd naar Exam Services",
          "successfully_linked": "Succesvol gekoppeld",
          "successfully_unlinked": "Succesvol ontkoppeld",
          "successfully_updated": "Succesvol geupdate",
          "successfully_uploaded": "Succesvol geüpload"
        },
        "copy_to_clipboard_success": "Succesvol gekopieerd",
        "criteria": {
          "add": {
            "points": "+ Voeg criterium toe",
            "rubrics": "+ Voeg niveau toe"
          },
          "copy": {
            "points": "+ Kopieer criteria",
            "rubrics": "+ Kopieer rubric"
          }
        },
        "criteria_functions": {
          "cant_update": "Kan de positie niet updaten voor invalide criteria"
        },
        "form": {
          "allow_multiple_attempts": "Sta meerdere pogingen toe",
          "limit_attempts": "Het aantal pogingen limiteren"
        },
        "generator": {
          "klasses_count": {
            "one": "1 klas geselecteerd",
            "other": "%{count} klassen geselecteerd"
          },
          "missing_content_html": {
            "one": "%{count} item bevat geen inhoud. Klik <span class='underline'>hier</span> om te bekijken.",
            "other": "%{count} items bevatten geen inhoud. Klik <span class='underline'>hier</span> om het eerste item te bekijken."
          }
        },
        "grade_calculation": {
          "grade_calculation_updated": "Cijferberekening succesvol gewijzigd"
        },
        "index": {
          "click_on_the_plus": "Klik op de plus om een toets te maken",
          "create_your_assignment": "Maak een toets"
        },
        "locations_count": {
          "one": "1 locatie geselecteerd",
          "other": "%{count} locaties geselecteerd"
        },
        "must_select_class": "Je moet een klas selecteren",
        "no_permission": "Je bent niet gemachtigd om een bericht te sturen",
        "path": {
          "question_count": {
            "one": "%{count} opgave",
            "other": "%{count} opgaven"
          }
        },
        "saving_failed": "Mislukt om scores op te slaan"
      },
      "changes": {
        "leave": "Verder",
        "leave_without_saving": "Pagina verlaten zonder opslaan?",
        "stay": "Blijven",
        "unsaved_changes": "Je hebt niet-opgeslagen veranderingen. Sla veranderingen op voor het verlaten van deze pagina, anders gaan deze verloren."
      },
      "courses": {
        "edit": {
          "periods_count": {
            "one": "1 periode geselecteerd",
            "other": "%{count} periodes geselecteerd"
          },
          "studies_count": {
            "one": "1 studie geselecteerd",
            "other": "%{count} studies geselecteerd"
          }
        },
        "feed": {
          "active": "Actief"
        }
      },
      "dashboard": {
        "active_students": {
          "users": "Gebruikers"
        },
        "instructor_charts": {
          "instructors": "Docenten"
        },
        "login_activity": {
          "logins": "Gebruikers ingelogd",
          "staff_logins": "Aanmeldingen van medewerkers",
          "student_logins": "Aanmeldingen van studenten"
        },
        "results_forecast": {
          "exam_results": "Papieren afnames",
          "expected_exam_results": "Verwachte papieren afnames",
          "expected_quiz_results": "Verwachte digitale afnames",
          "quiz_results": "Digitale afnames"
        }
      },
      "data_table": {
        "all_n_items_selected_html": "Alle <span class=\"text-semi-bold\">%{count}</span> items van deze tabel zijn geselecteerd.",
        "n_items_selected_html": "<span class=\"text-semi-bold\">%{count}</span> items zijn geselecteerd.",
        "select_all_n_items": "Alle %{count} items van deze tabel selecteren",
        "undo_selection": "Selectie ongedaan maken"
      },
      "domains": {
        "index": {
          "objectives": {
            "one": "%{count} leerdoel",
            "other": "%{count} leerdoelen",
            "zero": ""
          }
        },
        "select_domain": "Een domein selecteren om te kopieren",
        "show": {
          "new_objective": "Nieuw leerdoel"
        }
      },
      "dropzone": {
        "dictCancelUpload": "Upload annuleren",
        "dictCancelUploadConfirmation": "Weet je zeker dat je de upload wilt annuleren?",
        "dictDefaultMessage": "Bestanden hier slepen om te uploaden",
        "dictFallbackMessage": "Je browser ondersteund geen drag'n'drop",
        "dictFallbackText": "Het onderstaande formulier gebruiken om bestanden te uploaden",
        "dictFileTooBig": "Bestand is te groot ({{filesize}}MiB). Max bestandsgrootte: {{maxFilesize}}MiB.",
        "dictInvalidFileType": "Dit bestandstype is niet toegestaan.",
        "dictMaxFilesExceeded": "Je kunt niet meer bestanden uploaden.",
        "dictRemoveFile": "Bestand verwijderen",
        "dictResponseError": "Uploaden mislukt ({{statusCode}}).",
        "dictUploadCanceled": "Upload geannuleerd."
      },
      "feedback": {
        "message_templates": {
          "problem_html": "<strong>Samenvatting:</strong><br> <strong>Stappen om te reproduceren:</strong><br> <strong>Verwacht resultaat:</strong><br> <strong>Geobserveerd resultaat:</strong><br> <strong>URL waarop het probleem zich voordeed:</strong><br>"
        }
      },
      "general": {
        "are_you_sure": "Weet je het zeker?",
        "cancel": "Annuleren",
        "close": "Sluiten",
        "confirm": "Bevestigen",
        "connection_lost": "Je apparaat heeft zijn internetverbinding verloren.",
        "copy_failed": "Kopieren mislukt",
        "delete": "Verwijderen",
        "edit": "Bewerken",
        "formatting": "Opmaak",
        "insert": "Invoegen",
        "items": {
          "destroyed": {
            "one": "Item is permanent verwijderd",
            "other": "%{count} items zijn permanent verwijderd"
          },
          "no_permission_to_destroy": {
            "one": "Je hebt niet het recht om dit item permanent te verwijderen",
            "other": "Je hebt niet het recht om deze items permanent te verwijderen"
          },
          "no_permission_to_remove": {
            "one": "Je hebt niet het recht om dit item te verwijderen",
            "other": "Je hebt niet het recht om deze items te verwijderen"
          },
          "no_permission_to_restore": {
            "one": "Je hebt niet het recht om dit item te herstellen",
            "other": "Je hebt niet het recht om deze items te herstellen"
          },
          "removed": {
            "one": "Item is verwijderd",
            "other": "%{count} items zijn verwijderd"
          },
          "restored": {
            "one": "Item is hersteld",
            "other": "%{count} items zijn hersteld"
          }
        },
        "levels_count": {
          "one": "1 niveau geselecteerd",
          "other": "%{count} niveaus geselecteerd"
        },
        "no_content": "Geen content",
        "no_permission": "Je hebt hier geen rechten voor",
        "password_reset": "Email succesvol verzonden met wachtwoord instructies",
        "request_timeout": "Het request is mislukt, controleer je internetverbinding",
        "save": "Opslaan",
        "selected_count": {
          "one": "1 geselecteerd",
          "other": "%{count} geselecteerd"
        },
        "session_timedout": "Je sessie is verlopen. Je wordt naar de inlogpagina gestuurd in 5 seconden",
        "session_timeout": "Je sessie is verlopen. Log opnieuw in",
        "sort_failed": "Sorteren mislukt",
        "subjects_count": {
          "one": "1 vak geselecteerd",
          "other": "%{count} vakken geselecteerd"
        },
        "successfully_copied": "Succesvol gekopieerd",
        "successfully_created": "Succesvol aangemaakt",
        "successfully_deleted": "Succesvol verwijderd",
        "successfully_removed": "Succesvol naar de prullenbak verplaatst",
        "successfully_resubmitted": "Succesvol opnieuw ingeleverd",
        "successfully_submitted": "Succesvol ingeleverd",
        "successfully_updated": "Succesvol geupdate",
        "update": "Bijwerken",
        "update_failed": "Update mislukt",
        "updating": "Bijwerken...",
        "years_count": {
          "one": "1 leerjaar geselecteerd",
          "other": "%{count} leerjaren geselecteerd"
        }
      },
      "gradings": {
        "cant_save": "Kan bericht niet opslaan. Andere gebruiker is al bezig",
        "cant_update": "Kan vlag niet updaten. Vraag zit op slot door een andere gebruiker",
        "failed_score_save": "Scores opslaan is mislukt",
        "grading_undone": "Beoordeling ongedaan gemaakt",
        "no_internet": "Internet connectie is verbroken",
        "submission_flagged": "Vlag neergezet",
        "submission_unflagged_html": "Vlag weggehaald. <a href='#' class='undo-resolve-flag' data-id='%{id}'>Ongedaan maken</a>",
        "successfully_undone_flag": "Succesvol ongedaan gemaakt"
      },
      "highlights": {
        "cannot_overlap": "Mag niet overlappen met een andere highlight",
        "confirm_destroy": "Weet je het zeker? Alle opmerkingen worden ook verwijderd"
      },
      "hotspot": {
        "done_editing": "Klaar met bewerken",
        "select_area": "Gebied selecteren"
      },
      "integrations": {
        "query_copied": "Query gekopieerd naar klembord"
      },
      "klasses": {
        "class_name_email_student_number_missing": "Naam van de klas, e-mail en studentnummer zijn vereist",
        "email_student_number_missing": "Email en studentnummer zijn vereist",
        "successfully_imported": "Alle gebruikers zijn succesvol geïmporteerd"
      },
      "layouts": {
        "imported": "Succesvol geïmporteerd!",
        "invited": "Succesvol uitgenodigd!",
        "no_search_results": "Geen zoekresultaten",
        "pagy": {
          "confirm": "Dit leegt je selectie, weet je het zeker?"
        }
      },
      "limit_items": {
        "items_remaining": {
          "one": "Je kunt nog 1 item selecteren",
          "other": "Je kunt nog %{count} items selecteren"
        },
        "no_items_remaining": "Je kunt niet meer dan %{count} items selecteren"
      },
      "mathlive": {
        "add_column_after": "Kolom toevoegen achter",
        "add_column_before": "Kolom toevoegen voor",
        "add_row_after": "Rij toevoegen onder",
        "add_row_before": "Rij toevoegen boven",
        "borders": "Matrixscheidingstekens",
        "insert_matrix": "Matrix invoegen",
        "remove_column": "Verwijder kolom",
        "remove_row": "Verwijder rij"
      },
      "option_lists": {
        "new_gap": "Nieuw invulveld",
        "restore_gap": "Invulveld herstellen"
      },
      "page_collections": {
        "upload_failed": "Uploaden is mislukt, probeer het opnieuw",
        "uploading": "Uploaden"
      },
      "pagy": {
        "selected_count": {
          "one": "%{count} geselecteerd",
          "other": "%{count} geselecteerd",
          "zero": ""
        }
      },
      "questions": {
        "all_students": "Alle studenten",
        "groups": "Groepen",
        "groups_size": {
          "one": "1 groep",
          "other": "%{count} groepen"
        },
        "klasses": "Klassen",
        "points": {
          "one": "1 punt",
          "other": "%{count} punten"
        },
        "questions_size": "Hoeveelheid vragen",
        "select_questions": "Opgaven selecteren",
        "select_repo_to_store": "Een itembank selecteren",
        "stored_successfully": "Opgave succesvol bewaard",
        "subquestions": {
          "one": "1 opgave",
          "other": "%{count} opgaven"
        }
      },
      "quizzes": {
        "allow_screen_access": "Sta toegang tot je scherm toe",
        "allow_webcam_access": "Sta toegang tot je webcam toe",
        "already_locked": "Vergrendelen mislukt, deze vraag is al in gebruik door een andere gebruiker",
        "click_to_edit": "Klik om te bewerken",
        "invalid_cell_content": "Ongeldige inhoud gebruikt in cel",
        "locked_by_another": "Vraag is bezet door een andere gebruiker",
        "no_webcam_found": "Geen webcam gevonden",
        "plays_remaining": {
          "one": "Je kunt het mediafragment nog één keer afspelen",
          "other": "Resterend afspeellimiet <span class='px-2 b-han-purple white text-semi-bold rounded-1 ml-2'>%{count}</span>",
          "zero": "Je hebt de limiet van het aantal keer afspelen bereikt"
        },
        "proctorio_session_will_end": "De Proctorio sessie beëindigt hierbij",
        "quiz_navigation_panel": {
          "unanswered": {
            "one": "Je hebt %{count} onbeantwoorde vraag",
            "other": "Je hebt %{count} onbeantwoorde vragen",
            "zero": "Je hebt alle vragen ingevuld"
          }
        },
        "request_timeout": "Antwoorden niet opgeslagen, controleer je internetverbinding",
        "saving_in_progress": "Wachten tot antwoorden opgeslagen zijn",
        "select_shortcut": "Je moet een comment selecteren",
        "session_timeout": "Je sessie is verlopen. Log opnieuw in",
        "unsaved_changes": "Je hebt niet opgeslagen antwoorden, weet je zeker dat je weg wilt?"
      },
      "repo_assignments": {
        "cant_remove_question": "Je hebt geen machtiging om deze rij(en) te verwijderen",
        "question_removed": "Vragen verwijderd uit programma"
      },
      "repos": {
        "assignments": {
          "cant_remove_question": "Je hebt geen machtiging om deze rij(en) te verwijderen",
          "question_removed": "Vragen verwijderd uit programma"
        },
        "edit": {
          "studies_count": {
            "one": "1 studie geselecteerd",
            "other": "%{count} studies geselecteerd"
          }
        },
        "must_select_question": "Je moet een vraag selecteren om te kopieren",
        "users_removed": "Gebruikers verwijderd uit itembank"
      },
      "results": {
        "approved": "Resultat(en) goedgekeurd",
        "certainty_analysis": {
          "centration": "Centrering van het resultaat",
          "centration_over": "Overschat",
          "centration_under": "Onderschat",
          "correct": "Correcte antwoorden",
          "incorrect": "Incorrecte antwoorden"
        },
        "deleted": "Resultat(en) verwijderd",
        "disapproved": "Resultat(en) afgekeurd",
        "domains": {
          "assignment_date": "Toetsdatum",
          "median": "Mediaan",
          "median_percentile_reference_group": "Referentiegroep percentiel 50 (mediaan)",
          "nth_percentile": "%{count}e percentiel",
          "participant_score": "Deelnemer score",
          "percentile_reference_group_range": "Referentiegroep percentiel %{range}",
          "score": "Score"
        },
        "insights_requested": "Inzichten opgevraagd. Dit kan even duren...",
        "restored": "Resultat(en) hersteld",
        "total_points": "Totale punten"
      },
      "revenue": {
        "revenue": "Omzet"
      },
      "revenue_forecast": {
        "cumulative_revenue_forecast": "Cumulatieve omzetprognose",
        "monthy_revenue": "Maandelijkse inkomsten",
        "revenue_forecast": "Omzetprognose"
      },
      "scans": {
        "duplicate": "%{count} dubbele",
        "issues": {
          "one": "1 barcode niet herkend",
          "other": "%{count} barcode niet herkend"
        },
        "merge_and_next": "Samenvoegen & Volgende",
        "merge_failed": "Samenvoegen mislukt. Het aantal pagina's komt niet overeen met het vereist aantal pagina's.",
        "missing": "%{count} ontbrekend",
        "no_more_errors": "Einde van de foutenlijst bereikt",
        "scans_missing": "Pagina's ontbreken",
        "select_all_boxes": "Je moet alle vakjes aanvinken",
        "selected": {
          "one": "1 geselecteerd",
          "other": "%{count} geselecteerd",
          "zero": ""
        },
        "unknown": "onbekend",
        "upload_failed": "Uploaden mislukt. Probeer opnieuw of neem contact op met support",
        "user_not_found": "Gebruiker niet gevonden"
      },
      "subsets": {
        "question_timer_expired": "Tijdslimiet voor deze vraag verstreken. Nieuwste veranderingen niet opgeslagen."
      },
      "university_insights": {
        "subscriptions": {
          "subscriptions": "Abonnementen"
        }
      },
      "uploads": {
        "add_resource": "Nog geen bestanden toegevoegd",
        "failed": "Uploaden mislukt. Probeer opnieuw",
        "file_too_large": "Bestand is te groot, maximale bestandsgrootte is %{filesize}",
        "files_selected": {
          "one": "1 bestand geselecteerd",
          "other": "%{count} bestanden geselecteerd"
        },
        "not_found": "Bestand niet gevonden",
        "pending_uploads": "Uploads in afwachting",
        "unsupported_file_type": ".%{file_extension} wordt niet ondersteund, gelieve deze te plaatsen in een zip bestand"
      },
      "users": {
        "add": "Toevoegen",
        "adding": "Toevoegen...",
        "edit": {
          "hide_password": "Verberg wachtwoord",
          "show_password": "Laat wachtwoord zien"
        },
        "invite_resent": "Uitnodiging succesvol opnieuw verzonden",
        "invite_revoked": "Uitnodiging succesvol teruggetrokken",
        "please_select_two": "Selecteer twee gebruikers",
        "student_number_missing": "Studentnummer ontbreekt",
        "successfully_moved": "Succesvol verplaatst"
      }
    }
  },
  "nl_vo": {
    "js": {
      "dashboard": {
        "instructor_charts": {
          "instructors": "Docenten"
        },
        "login_activity": {
          "student_logins": "Aanmeldingen van leerlingen"
        }
      },
      "general": {
        "subjects_count": {
          "one": "1 vak geselecteerd",
          "other": "%{count} vakken geselecteerd"
        }
      },
      "klasses": {
        "class_name_email_student_number_missing": "Naam van de klas, email en leerlingnummer zijn vereist",
        "email_student_number_missing": "Email en leerlingnummer zijn vereist"
      },
      "questions": {
        "all_students": "Alle leerlingen"
      },
      "users": {
        "student_number_missing": "Leerlingnummer ontbreekt"
      }
    }
  }
}